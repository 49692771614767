import React, { useState, useEffect } from 'react'
import { NavLink, Link, BrowserRouter, Router, Switch, Route, useParams } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../asset/img/logo.png';
import logoBlue from '../asset/img/logoblue.png';
import { data } from 'jquery';
import { borderBottom } from '@mui/system';
import { useLocation } from "react-router-dom";
import './Navbar.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa'
// import Home from './pages/Home'

// import { createBrowserHistory } from 'history';

// const history = createBrowserHistory();

function Navbar() {

  // new 
  const { name } = useParams();
  const [content, setContent] = useState("");
  useEffect(() => {
    if (!name) {

    } else {
      setContent(name.substring(0));
    }
  }, [name]);






  const [icon, sectIcon] = useState(false)
  const handleClick = () => {
    sectIcon(!icon)
  }
  const closeSideDrawer = () => {
    sectIcon(false)
  }

  const [navColor, setnavColor] = useState("transparent");

  const [navbar, setNavbar] = useState(false)
  const [navlink, setLinks] = useState(false)
  const [blognavlink, setLinksblog] = useState(false)
  const [navbarLogo, setNavbarLogo] = useState(logo)
  const [hamburger, setHamburger] = useState(false)

  const blogchangeColor = () => {
    if (window.scrollY >= 60) {
      setLinksblog(true)
    } else {
      setLinksblog(false)
    }
  }
  useEffect(() => {
    blogchangeColor()
    // adding the event when scroll change background
    window.addEventListener("scroll", blogchangeColor)
  })

  const changeLogo = () => {
    if (window.scrollY >= 60) {
      setNavbarLogo(logoBlue)
      setHamburger(true)
    } else {
      setNavbarLogo(logo)
      setHamburger(false)
    }
  }

  useEffect(() => {
    changeLogo()
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeLogo)
  })

  const changeBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }


  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })



  const changeColor = () => {
    if (window.scrollY >= 60) {
      setLinks(true)
    } else {
      setLinks(false)
    }
  }
  useEffect(() => {
    changeColor()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeColor)
  })

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const activeURL = splitLocation[1];

  const error = activeURL == "Blog" || activeURL == "Blog-view" ? 'main-nav-active' : ""
  var error1 = blognavlink ? "blog-black" : " blognavlink"
  const classes = `${error1} ${error}`


  return (
    <>

      <nav className={navbar ? "navbar active" : "navbar"} sticky="top">
        <BrowserRouter forceRefresh={true}>   <Link to='/' className='nav-logo'
          onClick={closeSideDrawer}>
          <img
            className="d-block w-30 h-60"
            width="164"
            src={navbarLogo}
            alt="logo"
          />
        </Link></BrowserRouter>
        <div className="menu-icon">
          <a href="tel:9289745760" className={hamburger ? " color-black phone" : "phone"}><FaPhone></FaPhone></a>
          <a href="mailto:" className={hamburger ? "color-black phone ml-4-font" : "ms-0 phone ml-4-font"}><FaEnvelope></FaEnvelope></a>
          <div className={hamburger ? " color-black ml-8-font" : "ml-8-font"}
            onClick={handleClick}>
            {
              icon ? <FaTimes
                className='fa-times'></FaTimes> : <FaBars className='fa-bars'></FaBars>
            }
          </div>
        </div>

        <ul className={
          icon ? 'nav-menu p-0 active' :
            'nav-menu '
        }>

          <li className='nav-item dropdown'>
            <NavLink class="nav-links dropdown-toggle" exact to='#' id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Services
            </NavLink>
            <ul class="dropdown-menu custom-change" aria-labelledby="navbarDropdown">
              <NavLink className="navlink dropdown-item f-white" onClick={closeSideDrawer} exact to='/seo'>SEO</NavLink>
              <NavLink className="navlink dropdown-item f-white" onClick={closeSideDrawer} exact to='/social-media'>Social Media</NavLink>
              <NavLink className="navlink dropdown-item f-white" onClick={closeSideDrawer} exact to='/campaigns'>Campaigns</NavLink>
              <NavLink className="navlink dropdown-item f-white" onClick={closeSideDrawer} exact to='/ecommerce'>E-Commerce</NavLink>
              <NavLink className="navlink dropdown-item f-white" onClick={closeSideDrawer} exact to='/pr'>PR</NavLink>
            </ul>
          </li>
          <li>
            <NavLink exact to='/results'
              className={navlink ? "nav-links active" : "nav-links"} activeClassName='main-nav-active' onClick={closeSideDrawer}>Results</NavLink>
          </li>
          <li>
            <NavLink exact to="/blog" id='blogctive' className={navlink ? "nav-links active" : "nav-links"} activeClassName='main-nav-active' onClick={closeSideDrawer}>Blogs</NavLink>
            {/* <NavLink exact to={`${window.location.pathname.includes('blog-view') ? '/blog' : './blog'}`} id='blogctive' className={navlink ? "nav-links active" : "nav-links"} activeClassName='main-nav-active' onClick={closeSideDrawer}>Blogs</NavLink> */}
            {/* <NavLink exact to={`${window.location.pathname.includes('blog-view') ? '/blog' : (window.location.pathname.includes('/:name/digital-marketing-agency') ? './blog' : './blog')}`} id='blogctive' className={navlink ? "nav-links active" : "nav-links"} activeClassName='main-nav-active' onClick={closeSideDrawer}>Blogs</NavLink> */}

          </li>
          <li  >
            <NavLink exact to='/news'
              className={navlink ? "nav-links active" : "nav-links"} activeClassName='main-nav-active' onClick={closeSideDrawer}>News</NavLink>
          </li>

          <li>
            <NavLink exact to='/about'
              className={navlink ? "nav-links active" : "nav-links"} activeClassName='main-nav-active' onClick={closeSideDrawer}>About Us</NavLink>
          </li>



          <li>
            <NavLink exact to='/contact-us'
              className={navlink ? "nav-links active" : "nav-links"} activeClassName='main-nav-active' onClick={closeSideDrawer}>Contact Us</NavLink>
          </li>
        </ul>
      </nav>


    </>
  )
}

export default Navbar

