import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Footer from '../Components/Footer';
import Deco1 from '../asset/img/milton1.png'
import Deco2 from '../asset/img/milton2.png'
import Deco3 from '../asset/img/milton3.png'
// import Deco4 from '../asset/img/deco-result4.png'

function Miltonhomeappliances() {
    return (
        <div>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <div className='space-1 mt-10'>
                                </div>

                                <div className='deco-box1-edit'>
                                    <h1>
                                        WHO IS-
                                    </h1>
                                    <p>Milton Home Appliances is a 20 year+ old brand with 6 million+ happy customers of their kitchen appliances. They are a household brand all across India.</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <div className='space-2 mt-10'>
                                </div>

                                <div className="deco-box2-edit">
                                    <h1>
                                        Objective -
                                    </h1>
                                    <p>The goal was to successfully launch Milton Home Appliances on all major eCommerce Platforms & help them make the paradigm shift from Offline to Online.</p>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section2 maven    */}
            <div className="sec2-maven-bg mt-5">
                <Container>
                    <Row>
                        <Col className="challenges-sec">
                            <h1>Challenges -</h1>
                            <p>i) The Brand had no existing infrastructure to operate & execute eCommerce business.</p>
                            <p>ii) There was no brand awareness on any social media or eCommerce Platforms</p>
                            
                        </Col>
                    </Row>
                </Container>

            </div>
            {/* section3 maven */}
            <div className="">
                <Container>
                    <Row>
                        <Col className="deco-Approach-sec">
                            <h1>Our Approach-</h1>
                            <p>i) We evaluated top selling products on eCommerce Platforms from our niche and selected relevant products from the brand’s product range.</p>
                            <p>ii) The main goal was to initially get a little bit of traction so as to get things moving for us to start spending aggressively on promotions.</p>
                            <p>iii) Started with paid campaigns after 2 months with ACOS% being 14%</p>
                        </Col>
                    </Row>
                </Container>

            </div>
            {/* section4 deco */}
            {/* <div>
                <Container>
                    <Row>
                        <Col md={3}>

                        </Col>
                        <Col md={6}>
                            <div className='deco-box1-edit'>
                                <h1>
                                    Objective -
                                </h1>
                                <p>Deco-Arte is an interior designing studio that provides complete interior design solutions for restaurants, offices, hotels, homes, and retail spaces.</p>
                            </div>
                        </Col>
                        <Col md={3}>
                        </Col>
                    </Row>
                </Container>
            </div> */}

            {/* section4 maven */}
            <div className="mt-5">
                <Container>
                    <Row>
                        <Col className="deco-Approach-sec">
                            <h1>Results-</h1>


                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="deco-result-img mb-5">

                            <img
                                className="d-block mt-5"
                                width="100%"
                                src={Deco1}
                                alt="Sec-10"
                            />
                            <img
                                className="d-block mt-5"
                                width="100%"
                                src={Deco2}
                                alt="Sec-10"
                            />
                            <img
                                className="d-block mt-5"
                                width="100%"
                                src={Deco3}
                                alt="Sec-10"
                            />
                            

                        </Col>
                    </Row>
                </Container>


            </div>

            <Footer />
        </div>
    )
}

export default Miltonhomeappliances
