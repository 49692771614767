import React from 'react'
import {Row,Col,Container} from 'react-bootstrap';
import Footer from '../Components/Footer';
const Maven = () => {
    return (
        <div>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <div className='space-1 mt-10'>
                                    </div>
                                
                                    <div className='maven-box1-edit'>
                                        <h1>
                                        WHO IS-
                                        </h1>
                                        <p>Maven stainless is a leading manufacturer of Stainless Steel pipes & tubes having products in every desired shape & size..</p>
                                    </div>
                            </div>   
                        </Col>
                        <Col>
                            <div>
                                <div className='space-2 mt-10'>
                                    </div>
                                    
                                    <div className="maven-box2-edit">
                                        <h1>
                                        Objective -
                                        </h1>
                                        <p>To engage their existing network of Dealers, Fabricators, Interior Designers & Builders on Social Media Platforms & Increase brand visibility amongst the competition.</p>
                                    </div>
                                   
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
             {/* section2 maven    */}
             <div className="sec2-maven-bg mt-5">
                 <Container>
                     <Row>
                         <Col className="challenges-sec">
                         <h1>Challenges -</h1>
                         <p>i) We had to start working on a fresh account where everything had to be done from scratch.</p>
                         <p>ii) The Client had a b2b product with a very niche target audience</p>
                         <p>iii) ROI was getting engagement from only & only the target audience</p>
                         </Col>
                     </Row>
                 </Container>

             </div>
             {/* section3 maven */}
             <div className="sec3-maven-bg mt-5">
                 <Container>
                     <Row>
                         <Col className="Approach-sec">
                         <h1>Our Approach-</h1>
                         <p>i) Determined marketing channel mix and approach by creating audience profiles based on client’s inputs & data of the dealer network and catering to all sets of target audiences individually by promoting relevant content.</p>
                         <p>ii) After creating a base with relevant content for all audiences we planned a creative engagement campaign to target a broad set of audiences while encouraging the current dealer network to participate in the campaign.</p>
                         <p>iii) Using data provided by the client we targeted the existing dealer network & also created lookalike audiences to increase the reach of the campaign.</p>
                         </Col>
                     </Row>
                 </Container>

             </div>
             {/* section4 maven */}
             <div className="sec2-maven-bg mt-5">
                 <Container>
                     <Row>
                         <Col className="challenges-sec">
                         <h1>Results- -</h1>
                         <p>i) The initial user education phase was slow as expected but we gained good traction and were able to place the brand Maven Stainless amongst their competitors with a much higher marketing budget.</p>
                         <p>iI) The engagement campaign was a huge success, we will let the video talk for us -</p>
                         
                         </Col>
                     </Row>
                 </Container>

             </div>

            <Footer/>
        </div>
    )
}

export default Maven
