import { MD5 } from "../util/md5";
import axios from "axios";

// export const BASE_URL = 'https://dev.technotackle.com/digidznB/admin/web/api/';
// export const BASE_IMAGE_URL = 'https://dev.technotackle.com/digidznB/admin/web/';
// export const MASTER_IMAGE_URL = 'https://dev.technotackle.com/digidznB/admin/web/images/blogs/';
export const BASE_URL = 'https://digidzn.com/digidzn-admin/web/api/';
export const BASE_IMAGE_URL = 'https://digidzn.com/digidzn-admin/web/';
export const MASTER_IMAGE_URL = 'https://digidzn.com/digidzn-admin/web/images/blogs/';
export const salt = 'BL0GDzn*&^!@2022';

export const MD5Hash = (data) =>{
   return MD5(salt+data)
}

export const BLOG_LIST = 'blogs/blogs-list';
export const BLOG_VIEW = 'blogs/blog-view';
export const BLOG_RECENT_POST = 'blogs/recent-posts';
export const BLOG_CREATE_COMMENT = 'blog-comments/create-comment';
export const BLOG_COMMENT_LIST = 'blog-comments/comment-list';
export const BLOG_RECENT_COMMENTS = 'blog-comments/recent-comments';
export const BLOG_CATEGORY_LIST = 'blogs/category-lists';
export const BLOG_SEARCH = 'blogs/blog-search';
export const CATEGORY_DETAILS = 'blogs/category-view';
export const BLOG_CATEGORY_LISTS = 'blogs/blog-category-list';

let headerParam = {
  // auth_token: MD5Hash(),
  // Authorization: `${USER_ID()}`,
  Accept: "application/json",
  "Content-Type": "application/json",
  // "Access-Control-Allow-Origin": "*",
  // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
};

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: headerParam,
});

// export const axiosInstance = axiosInstances;

//post method
export const postRequest = async (url, body) => {
  try {
    const res = await (await axiosInstance.post(url, body)).data;
    // console.log(res);
    return res;
  } catch (e) {
    console.log(e)
    return { success: false, message: e.message, body: {} };
  }
};