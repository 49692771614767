import axios from 'axios';
import Footer from '../Components/Footer';
import React, { useEffect, useState,useRef  } from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Figure, Card, Form,FormControl,Button } from 'react-bootstrap';
import { useParams, useRouteMatch, useLocation, useHistory, Link  } from 'react-router-dom';
import Blogimg from '../asset/img/blogpost1.jpeg';
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser, faClock, faCommentDots, faSearch } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { FaRegCalendarAlt} from 'react-icons/fa'
import spinner from '../asset/loader/Spinner-1s-200px.gif'
import {valueEmptyCheck,emailValidator,validateWebsite} from '../util/validation'
import { postRequest, MD5Hash, BLOG_LIST, BASE_IMAGE_URL, 
  BLOG_VIEW,salt, BLOG_RECENT_POST,BLOG_CREATE_COMMENT, BLOG_COMMENT_LIST,BLOG_RECENT_COMMENTS,BLOG_CATEGORY_LIST,BLOG_SEARCH
} from '../util/api';

// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

const BlogView = (data) => {
// const [date , setDate] = {}


  const location = useLocation()
  const [blogview, setBlogview] = useState([]);
  const [blogCommentList, setBlogCommentList] = useState([]);
  const [socialIcons, setSocialIcons] = useState({
    social:[]
  });
  const [loader,setLoader] = useState(true)
  const path = location.pathname.split('/');
  let id = parseInt(path[2]);
  let seoUrl = path[2];
  console.log(path);
  const [createComment, setCreateComment] = useState({
    name:"",
    email:"",
    website:"",
    comments:id,
    blog_id:id,
    auth_token:""
  });
  
  const focusDiv = useRef(null)
  // const executeScroll = () => scrollToRef(focusDiv)

  const [recent_blogs, setRecentBlogs] = useState({
    recent:[]
  });
  const [recent_comments, setRecentComments] = useState();
  const [blogCategoryList, setBlogCategoryList] = useState();
  const [blogSearch, setSearchValue] = useState({
    search_input:"",
    auth_token:""
  });
  const [CommentId,setCommentId] = useState()
  const regex = /(<([^>]+)>)/ig;
  

  useEffect(() => {
    blog_view();
    recentPost();
    blog_comment_list();
    recentComments();
    categoryList();
    // executeScroll();
  }, [])

  

  const blog_view = async () => {
    let auth_token = MD5Hash(seoUrl);
    let data = {
      "auth_token": auth_token,
      "seo_url":seoUrl,
    }
    var res = await postRequest(BLOG_VIEW, data);
    
    if (res.success) {
      
      setLoader(false)
      setBlogview(res?.parameters?.data);
      setSocialIcons({
        ...socialIcons,
        social:res?.parameters?.data[0].socialmedia_icons?.split(',')
      })
    }
  }

  let blogComt = blogview[0]?.blogComments;
  
  // console.log(blogComt);
  
  

  // slip date and time 

  let sep_date1 = blogview[0]?.start_datetime;
  let new_datetime = sep_date1?.split(" ");
  let startDate = new_datetime?new_datetime[0]:null;
  let starttime = new_datetime?new_datetime[1]:null;
 

  const sep_months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
  let separate_dates = blogview[0]?.start_datetime;
  let new_date = separate_dates?.split("-");
  let sep_new_date = new_date? new_date[2].split(" ")[0]: null;
  let date = sep_new_date
  let month = new_date?new_date[1]:null
  let year = new_date?new_date[0]:null
  function zeller(day, month, year) {
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    if (month < 3) {
      month += 12;
      year--;
    }
    var h = (day + parseInt(((month + 1) * 26) / 10) +
      year + parseInt(year / 4) + 6 * parseInt(year / 100) +
      parseInt(year / 400) - 1) % 7;
    return weekday[h];
    
  }
  // console.log('dates',parseInt(date), parseInt(month), parseInt(year))
  let day = zeller(parseInt(date), parseInt(month), parseInt(year))
  let values = `${day} ${sep_months[parseInt(month)-1]} ${date}  ${year}`

    
  // }

  // let values = `${months[parseInt(month)-1]} ${} ${year}`;
  let history = useHistory();
  // const handleSearchBlog = () =>{

  //   history.push("./search-blog");
  //   // window.location = "./search-blog";
  //   // window.location.replace("/search-blog");
  // }


  const recentPost = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_RECENT_POST, data);
    if (res.success) {
      setRecentBlogs({
        ...recent_blogs,
        recent:res.parameters
      })
      
    }
  }

  const recentComments = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_RECENT_COMMENTS, data);
    if (res.success) {
      setRecentComments(res?.parameters) 
      console.log("response id" , res)
      

    }
  }

  const categoryList = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_CATEGORY_LIST, data);
    if (res.success) {
      setBlogCategoryList(res?.parameters) 
    }
  }


  
 
 const createComments = async() =>{

  if (!valueEmptyCheck(createComment.comments)) {
    alert('Comments is required')
    return
  }
  if (!valueEmptyCheck(createComment.name)) {
    alert('Name is required')
    return
  }
  if (!valueEmptyCheck(createComment.email)) {
    alert('Email is required')
    return
  }
  if (!emailValidator(createComment.email)  ) {
    alert('Email Is Invalid Format')
    return
  }
  if (!valueEmptyCheck(createComment.website)) {
    alert('Website is required')
    return
  }
  
  if(!validateWebsite(createComment.website)){
    alert('Website is Invalid format')
    return
  }
 
    createComment.auth_token = MD5Hash(createComment.email)

    var res = await postRequest(BLOG_CREATE_COMMENT, createComment);
  

    if(res.success == true){
      alert(res.message);
      window.location.reload();
      blog_comment_list();
      // createComment.name="";
      // createComment.comments="";
      // createComment.website = "";
      // createComment.email="";
      setCreateComment({
        name:"",
        email:"",
        website:"",
        comments:"",
      })
      
    }

    
  }

  const blog_comment_list = async () => {
    let auth_token = MD5Hash(id);
    let data = {
      "blog_id":id,
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_COMMENT_LIST, data);
    if (res.success) {
      setBlogCommentList(res?.parameters);
    }
  }

  const blog_search = async (e) => {
    blogSearch.auth_token = MD5Hash("");
    var res = await postRequest(BLOG_SEARCH, blogSearch);
    if (res.success) {
      // setBlogCommentList(res?.parameters);
    }
  }
     
 
//  console.log(typeof(urldata1));

  return (
    <>{loader == true? <div  className='sloader'><img src={spinner}></img></div>:
    
    <div className="full-detail">
      <Helmet>
                <title>{blogview[0]?.blog_name}</title>
                <meta name="description" content="Worried about growth hacking marketing strategies for Campaigns? Be it Facebook Ads or Google Ads, we are pro in handling and marketing digital campaigns. We not only create campaigns for you but also track campaigns for you."/>
            </Helmet>

      <div className="explore-container">
        <div>
          <Container>
            <Row>
              <Col className='mt-10'>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col md={8} className='mt-5'>
              {
                  blogview[0]?.blog_image_url != null ? (
                    <img
                      width='100%' className='blog-view-img'
                      src={BASE_IMAGE_URL+blogview[0]?.blog_image_url}
                      alt='blog-img'
                    />
                  ) : (
                    <div className='no-img-found'>No image</div>
                  )}
                <div className='icon-div d-flex align-items-center'>
                <span className='icon-txt1 d-flex '><FontAwesomeIcon icon={faCircleUser}  size="lg" color="grey" /><p className='icon-pos'>{blogview[0]?.master_name}</p></span>
                <span className='icon-txt2 d-flex '> <FaRegCalendarAlt color="grey" /><p className='icon-pos'>{startDate}</p> &nbsp; &nbsp; <FontAwesomeIcon icon={faClock}  size="lg" color="grey" /><p className='icon-pos'>{starttime}</p></span> 
                <span className='icon-txt3 d-flex '><FontAwesomeIcon icon={faCommentDots} size="lg" color="grey" /><p className='icon-pos'>Comments Count : {blogview[0]?.blogCommentsCount}</p></span>
                </div>
                <div className='social-icon-div'>
                  {
                      socialIcons?.social?.map((item)=>{
                        if(item == 'facebook'){
                          return(
                            <img style={{marginRight: "10px"}} className='icon-s' src="../fb.png"/>
                          )
                        }else if(item == 'twitter'){
                          return(
                            <img className='icon-s' src="../twitter.png"/>
                          )
                        }else if(item == 'instagram'){
                          return(
                            <img style={{marginLeft: "10px"}} className='icon-s' src="../insta.png"/>
                          )
                        }
                      })
                    }
                </div>
                
                <div className='blogpost-date-ver'> 
                <div className='blogpost-date'><h2>{values}</h2></div>
                </div>
                
                <div className="img-size">
                  
                  <h1 className='blog_title'>{blogview[0]?.blog_name}</h1>
                {/* <div>{blogview[0]?.description.replace(regex, '')}</div> */}
                <div className="img-size1">{ReactHtmlParser(blogview[0]?.description)}</div>
                </div>
                {/* <Form id="create-comment-form" > */}

                
                <div className="comment_list">
                  {
                    
                blogCommentList?.map((item, index)=>{
                      return(
                        <div>
                          <div className="comment-profile mt-4">
                            <img className='blog-view-img1' src="../comment-user.png" />
                            <div className='details'>
                              <h4 className='c-name'>{item?.name}</h4>
                              <h4 className='c-date'>{item?.added_at}</h4>
                              
                          </div>
                        </div>
                          <p className='content-p'>{item?.comments}</p>
                          <p className='rp-txt'><i>{item?.replay_description != null ? "Reply":""}</i></p>
                          <span className='reply-txt'>{(item?.replay_description == null) ? "" : item?.replay_description}</span>
                        </div>
                      )
                    })
                  }
                  
                </div>
                <div className='comment-form' id="blogform" ref={focusDiv}>
                    <div className='comment-h'>Leave a Comment</div>
                    <div>Your email address will not be published. Required fields are marked *</div>
                    
                    <textarea onChange={(e)=> 
                      setCreateComment({
                        ...createComment,
                        comments:e.target.value
                      })
                    } aria-required="true" 
                    className='comment-ip'
                    // value={createComment.comments}
                     cols="45" id="comment" name="comment" placeholder="Type here.. *" rows="8"></textarea>
                    <Row>
                      <Col>
                      <input 
                      onChange={(e) =>
                        {
                          setCreateComment({
                            ...createComment,
                            name:e.target.value
                          })
                        }
                         } 
                      className='input-b' 
                      value={createComment.name}
                      placeholder="Name *" type="text"/></Col>
                      <Col>
                      <input 
                      onChange={(e) => setCreateComment({
                        ...createComment,
                        email:e.target.value
                      }) } 
                      className='input-b' 
                      value={createComment.email}
                      placeholder="Email *"  type="email"/></Col>
                      <Col>
                      <input 
                      onChange={(e) => setCreateComment({
                        ...createComment,
                        website:e.target.value
                      }) } 
                      className='input-b' value={createComment.website} placeholder="Website *"  type="text"/></Col>
                    </Row> 
                    
                </div>
                  <div className='checkDiv'>
                    <input type="checkbox"/><span className='check-txt'>Save my name, email, and website in this browser for the next time I comment.</span>
                  </div>

                  <div className='mb-5'>
                    <button onClick={createComments} type="submit" className='post-btn'>Post Comment</button>
                  </div>
                  {/* </Form> */}
              </Col>
              <Col md={1}></Col>
              <Col md={3} className='mt-5'>
                <div>
                  <input type="text" onChange={(e)=>{
                    setSearchValue({
                      ...blogSearch,
                      search_input:e.target.value
                    })
                    blog_search(e)
                  }} placeholder="Search" className="search-ip"  /> 
                  
                  <Link className="read-more-txt" to={`/Blog-search-view/${blogSearch.search_input}`}  ><FontAwesomeIcon icon={faSearch} className="fa-seacrh-icon" size="lg" color="grey" /> 
                 </Link>
                </div>
                <div className='recent-div'>
                  <div className='recent-post-div'>Recent Posts</div>
                  {
                    recent_blogs?.recent?.map((item)=>{
                      return(
                        // <a className='txt-size'>{item?.blog_name}</a>
                        <p><a className="txt-size" href={`/blog-view/${item.seo_url}`} >{item?.blog_name}</a></p>
                      )
                    })
                  }
                </div>

                {/* <div className='recent-div'>
                  <div className='recent-post-div'>Recent Comments</div>
                  {
                    recent_comments?.map((item)=>{
                      return(
                        <p><a className='txt-size'>{item?.comments}</a></p>
                      )
                    })
                  }
                </div> */}

                <div className='recent-div'>
                  <div className='recent-post-div'>Categories</div>
                  {
                    blogCategoryList?.map((item)=>{
                      return(
                        // <p><a className='txt-size'>{item?.category_name}</a></p>
                        <p><a className="txt-size" href={`/category-details/${item.id}`} >{item?.category_name}</a></p>
                      )
                    })
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        

      </div>
      <Footer />
    </div>}</>
 )
}

export default BlogView




