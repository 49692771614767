import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Icon32 from '../asset/img/Deco.png';
import Icon33 from '../asset/img/na.png';
import Icon34 from '../asset/img/m.png';
import './testimonial.css';
import Client3 from '../asset/img/client3.png'
import Client4 from '../asset/img/client4.png'
import Client5 from '../asset/img/client5.png'
import Client6 from '../asset/img/client6.png'
import Client1 from '../asset/img/Amardeep.webp'
import Client2 from '../asset/img/client2.webp'


function TestimonialNew() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 2.5 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <div>
            <Container fluid>
                <div className="mt-5 mb-5 pl-3 py-3 pr-0 cursor-point">
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        infinite={true}
                        customTransition="transform 2000ms ease-in-out"
                        transitionDuration={2000}
                        removeArrowOnDeviceType={["tablet", "mobile"]}

                        arrows={false}
                        focusOnSelect={false}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                    >

                        <div>
                            <div className='border-right-color p-4'>
                                <div className='mb-4'>
                                    <img src={Icon32} width="200"></img>
                                </div>

                                <div className="mb-4 title-testi">
                                    <h3>Having a trusted partner like digiDZN</h3>
                                </div>
                                <div className="mb-4 title-testi">
                                    <p>Having a trusted partner like digiDZN has actually helped me to grow my business at a pace! The team helped me to reach the audience with speedy growth tactics and creative campaign ideas</p>
                                </div>
                                <div className="profile-imagge">
                                    <div className="d-flex justify-content-initial">
                                        <div className="image-profile">
                                            <img src={Client1} width="60"></img>
                                        </div>
                                        <div className="profile-name mt-2">
                                            <h4 className="mb-0">Amardeep Gulri</h4>
                                            <p>Deco Arte</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='border-right-color p-4'>
                                <div className='mb-4'>
                                    <img src={Icon33} width="200"></img>
                                </div>

                                <div className="mb-4 title-testi">
                                    <h3>It is truly amazing to work with digiDZN team</h3>
                                </div>
                                <div className="mb-4 title-testi">
                                    <p>They work very efficiently and complete all deliverables on time...The best part about them is their in-depth knowledge about their subject, they are highly professional !!!</p>
                                </div>
                                <div className="profile-imagge">
                                    <div className="d-flex justify-content-initial">
                                        <div className="image-profile">
                                            <img src={Client1} width="60"></img>
                                        </div>
                                        <div className="profile-name mt-2">
                                            <h4 className="mb-0">Piyush</h4>
                                            <p>Orgo All-Natural</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='border-right-color p-4'>
                                <div className='mb-4'>
                                    <img src={Icon34} width="200"></img>
                                </div>

                                <div className="mb-4 title-testi">
                                    <h3>Creative and experienced professionals to deliver</h3>
                                </div>
                                <div className="mb-4 title-testi">
                                    <p>They are creative and experienced professionals to deliver great results and growth. With their methodological SEO approach and digital marketing strategy helped us to become favourite among our customers! We are excited to continue our relationship with them.</p>
                                </div>
                                <div className="profile-imagge">
                                    <div className="d-flex justify-content-initial">
                                        <div className="image-profile">
                                            <img src={Client5} width="60"></img>
                                        </div>
                                        <div className="profile-name mt-2">
                                            <h4 className="mb-0">Akshay Sharma </h4>
                                            <p>Mallu Farms</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </Container>
        </div>
    )
}

export default TestimonialNew;