import React from 'react'
import { useEffect, Suspense } from 'react';
import './App.css'
import { BrowserRouter as Router, Switch, Route, HashRouter, Redirect } from 'react-router-dom'
import { Row, Col, Container, Figure } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Contactus from './pages/Contactus'
import Results from './pages/Results';
import Socialmedia from './pages/Socialmedia'
import Seo from './pages/Seo'
import Digitalmarketinginternship from './pages/Digitalmarketinginternship'
import Campaigns from './pages/Campaigns'
import Maven from './pages/Maven-stainless-steel'
import Deco from './pages/Deco-arte'
import Thankyou from './pages/Thankyou'
import Pr from './pages/Pr'
import Ecommerce from './pages/Ecommerce'
import Forbusiness from './pages/Forbusiness'
import Franchise from './pages/Franchise'
import Forstudent from './pages/Forstudent'
import ScrollToTop from './Components/ScrollToTop';
import ReactGA from 'react-ga';
import Famphy from './pages/Famphy'
import Blog from './pages/Blog'
import Miltonhomeappliances from './pages/Miltonhomeappliances';
import blogView from './pages/Blog-view';
import SearchBlogViewList from './pages/Blog-search-view';
import CategoriesDetails from './pages/Category-details';
import News from './pages/News';

// import services from './pages/Services'
// import Footer from './Components/Footer';
function App() {
  useEffect(() => {
    ReactGA.initialize('UA-118262071-1');
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  // useEffect(() => {
  //  console.log(window.location.pathname)
  // })

  return (
    <Router >
      <ScrollToTop />
      <Navbar />
      <Suspense fallback={<div style={{ marginTop: "130px" }}>Loading.....</div>}></Suspense>

      {/* <Switch>
        <Route path='/' exact forceRefresh={false} component={Home} />
        <Route path='/about' component={About} />
        <Route path='/services' component={Services} />
        <Route path='/contact-us' component={Contactus} />
        <Route path='/results' component={Results} />
        <Route path='/digitalmarketinginternship' component={Digitalmarketinginternship} />
        <Route path='/social-media' component={Socialmedia} />
        <Route path='/seo' component={Seo} />
        <Route path='/campaigns' component={Campaigns} />
        <Route path='/maven-stainless-steel' component={Maven} />
        <Route path='/deco-arte' component={Deco} />
        <Route path='/pr' component={Pr} />
        <Route path='/ecommerce' component={Ecommerce} />
        <Route path='/for-business' component={Forbusiness} />
        <Route path='/for-students' component={Forstudent} />
        <Route path='/news' component={News} />
        <Route path='/milton-home-appliances-premium-kitchen-products' component={Miltonhomeappliances} />
        <Route path='/famphy-doctor-on-call-for-non-critical-illness' component={Famphy} />
        <Route path='/Blog' component={Blog} />
        <Route path='/Blog-view' component={blogView} />
        <Route path='/Blog-search-view' component={SearchBlogViewList} />
        <Route path='/category-details' component={CategoriesDetails} />
        <Route exact path='/thank-you-page' forceRefresh={true} component={Thankyou} />
        <Route exact path="/:name/digital-marketing-agency" component={Home} />
      </Switch> */}

      <Switch>
        <Route exact path="/" component={Home} />
        <Route path='/about' component={About} />
        <Route path='/services' component={Services} />
        <Route path='/contact-us' component={Contactus} />
        <Route path='/results' component={Results} />
        <Route path='/digitalmarketinginternship' component={Digitalmarketinginternship} />
        <Route path='/social-media' component={Socialmedia} />
        <Route path='/seo' component={Seo} />
        <Route path='/campaigns' component={Campaigns} />
        <Route path='/maven-stainless-steel' component={Maven} />
        <Route path='/deco-arte' component={Deco} />
        <Route path='/pr' component={Pr} />
        <Route path='/ecommerce' component={Ecommerce} />
        <Route path='/for-business' component={Forbusiness} />
        <Route path='/for-students' component={Forstudent} />
        <Route path="/news" component={News} />
        <Route path='/milton-home-appliances-premium-kitchen-products' component={Miltonhomeappliances} />
        <Route path='/famphy-doctor-on-call-for-non-critical-illness' component={Famphy} />
        <Route path="/blog" component={Blog} />
        <Route path='/Blog-view/' component={blogView} />
        <Route path='/Blog-search-view' component={SearchBlogViewList} />
        <Route path='/category-details' component={CategoriesDetails} />
        <Route path='/thank-you-page' component={Thankyou} />

        <Route exact path="/:name/digital-marketing-agency/" component={Home} />
        <Route path="/:name/digital-marketing-agency/about" component={About} />
        <Route path="/:name/digital-marketing-agency/services" component={Services} />
        <Route path="/:name/digital-marketing-agency/contact-us" component={Contactus} />
        <Route path="/:name/digital-marketing-agency/results" component={Results} />
        <Route path="/:name/digital-marketing-agency/digitalmarketinginternship" component={Digitalmarketinginternship} />
        <Route path="/:name/digital-marketing-agency/social-media" component={Socialmedia} />
        <Route path="/:name/digital-marketing-agency/seo" component={Seo} />
        <Route path="/:name/digital-marketing-agency/campaigns" component={Campaigns} />
        <Route path="/:name/digital-marketing-agency/maven-stainless-steel" component={Maven} />
        <Route path="/:name/digital-marketing-agency/deco-arte" component={Deco} />
        <Route path="/:name/digital-marketing-agency/pr" component={Pr} />
        <Route path="/:name/digital-marketing-agency/ecommerce" component={Ecommerce} />
        <Route path="/:name/digital-marketing-agency/for-business" component={Forbusiness} />
        <Route path="/:name/digital-marketing-agency/for-students" component={Forstudent} />
        <Route path="/:name/digital-marketing-agency/news" component={News} />
        <Route path="/:name/digital-marketing-agency/milton-home-appliances-premium-kitchen-products" component={Miltonhomeappliances} />
        <Route path="/:name/digital-marketing-agency/famphy-doctor-on-call-for-non-critical-illness" component={Famphy} />
        <Route path="/:name/digital-marketing-agency/blog" component={Blog} />
        <Route path="/:name/digital-marketing-agency/Blog-view/" component={blogView} />
        <Route path="/:name/digital-marketing-agency/Blog-search-view" component={SearchBlogViewList} />
        <Route path="/:name/digital-marketing-agency/category-details" component={CategoriesDetails} />
        <Route path="/:name/digital-marketing-agency/thank-you-page" component={Thankyou} />

        <Redirect to="/" />
      </Switch>
    </Router>

  )
}

export default App
