import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Icon1 from '../asset/img/logo_new/1.png'
import Icon2 from '../asset/img/logo_new/2.png'
import Icon3 from '../asset/img/logo_new/3.png'
import Icon4 from '../asset/img/logo_new/4.png'
import Icon5 from '../asset/img/logo_new/5.png'
import Icon6 from '../asset/img/logo_new/6.png'
import Icon7 from '../asset/img/logo_new/7.png'
import Icon8 from '../asset/img/logo_new/8.png'
import Icon9 from '../asset/img/logo_new/9.png'
import Icon10 from '../asset/img/logo_new/10.png'
import Icon11 from '../asset/img/logo_new/11.png'
import Icon12 from '../asset/img/logo_new/12.png'
import Icon13 from '../asset/img/logo_new/13.png'
import Icon14 from '../asset/img/logo_new/14.png'
import Icon15 from '../asset/img/logo_new/15.png'
import Icon16 from '../asset/img/logo_new/16.png'
import Icon17 from '../asset/img/logo_new/17.png'
import Icon18 from '../asset/img/logo_new/18.png'
import Icon19 from '../asset/img/logo_new/19.png'
import Icon20 from '../asset/img/logo_new/20.png'
import Icon21 from '../asset/img/logo_new/21.png'
import Icon22 from '../asset/img/logo_new/22.png'
import Icon23 from '../asset/img/logo_new/23.png'
import Icon24 from '../asset/img/logo_new/24.png'
import Icon25 from '../asset/img/logo_new/25.png'
import Icon26 from '../asset/img/logo_new/26.png'
import Icon27 from '../asset/img/logo_new/27.png'
import Icon28 from '../asset/img/logo_new/28.png'
import Icon29 from '../asset/img/logo_new/29.png'
import Icon30 from '../asset/img/logo_new/30.png'
import Icon31 from '../asset/img/logo_new/31.png'
import Icon32 from '../asset/img/logo_new/32.png'
import Icon33 from '../asset/img/logo_new/33.png'
import Icon34 from '../asset/img/logo_new/34.png'
import Icon35 from '../asset/img/logo_new/35.png'
import Icon36 from '../asset/img/logo_new/36.png'
import Icon37 from '../asset/img/logo_new/37.png'




function LogoSlider() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: { 
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <div>
            <div className="py-5"></div>

            <Container>
                <div className="bordertop"></div>
                <Col md={10} className="mt-5">
                    <h3 className='h4 text-center text-md-start text-white'>Established corporates to well-known Retail brands to startups - we have served them all.</h3>
                </Col>
                <div className="mt-5 mb-5 pl-3 py-3 pr-0 cursor-point">
                    <Carousel
                        swipeable={false}
                        draggable={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        customTransition="transform 2000ms ease-in-out"
                        transitionDuration={2000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        partialVisible={true}
                        arrows={false}
                        autoPlaySpeed={2000}
                        showDots={false}
                        focusOnSelect={false}
                    >
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon1}
                                    alt="Icon1"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon2}
                                    alt="Icon2"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon3}
                                    alt="Icon3"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon4}
                                    alt="Icon4"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon5}
                                    alt="Icon5"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon6}
                                    alt="Icon6"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon7}
                                    alt="Icon7"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon8}
                                    alt="Icon8"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon9}
                                    alt="Icon9"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon10}
                                    alt="Icon10"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon11}
                                    alt="Icon11"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon12}
                                    alt="Icon12"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon13}
                                    alt="Icon13"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon14}
                                    alt="Icon14"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon15}
                                    alt="Icon15"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon16}
                                    alt="Icon16"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon17}
                                    alt="Icon17"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon18}
                                    alt="Icon18"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon19}
                                    alt="Icon19"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon20}
                                    alt="Icon20"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon21}
                                    alt="Icon21"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon22}
                                    alt="Icon22"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon23}
                                    alt="Icon23"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon24}
                                    alt="Icon24"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon25}
                                    alt="Icon25"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon26}
                                    alt="Icon27"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon28}
                                    alt="Icon28"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon29}
                                    alt="Icon29"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon30}
                                    alt="Icon30"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon31}
                                    alt="Icon31"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon32}
                                    alt="Icon33"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon34}
                                    alt="Icon34"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon35}
                                    alt="Icon35"
                                />
                            </div>
                            <div md={2} className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon36}
                                    alt="Icon36"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Icon37}
                                    alt="Icon37"
                                />
                            </div>
                        </div>
                        



                    </Carousel>
                </div>
                <div className="borderbottom"></div>
                <div className="py-5"></div>
            </Container>
        </div>
    )
}

export default LogoSlider;