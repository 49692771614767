import React from 'react'
import { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa'
import Footer from '../Components/Footer';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'AW-673970975'
}

TagManager.initialize(tagManagerArgs)


const Thankyou = () => {
    useEffect(() => {
        ReactGA.initialize('UA-118262071-1');
        // To Report Page View 
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        console.log(window.location.pathname)
    })

    return (
        <div>


            <div className="thankyou-banner">
                <Container>
                    <Row>
                        <Col className="thankyou-content animated fadeInLeft">
                            <h1>Thankyou for submitting</h1>
                            <FaCheckCircle className="icon-thankyou"></FaCheckCircle>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Thankyou
