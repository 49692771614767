import React from 'react'
import { Row, Col, Container, Figure } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Footer from '../Components/Footer';
import Stdanimation from '../Components/Stdanimation';
import Slider from "react-slick";
import  { Redirect } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Pdf from '../asset/img/Revised.pdf';
import Testi1 from '../asset/img/testi1.jpg'
import Testi2 from '../asset/img/testi2.jpg'
import Testi3 from '../asset/img/worker4.jpg'
import Stdbanner from '../asset/img/std-banner.png';
import { FaUserClock, FaProjectDiagram, FaRegEdit, FaUserAlt, FaCalendarAlt } from 'react-icons/fa'
function Forstudent() {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const initialValues = { name: null, email: null, phonenumber: null, message: null, recaptcha: null };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isVerified, setVerified] = useState(null);
    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

    };

    // if (this.state.verified) {
    //     fetch()
    //     .then(this.handleSuccess)
    //     .catch(error => alert(error))
    //     .preventDefault()
    // }
    // else {this.setState({reply: "Please verify the ReCaptcha."})}
    React.useEffect(() => {
        // register({ name: "spam" }, { required: "prove you are no bot plz!" });
    }, []);

    const handleRecaptcha = value => {
        console.log("Captcha value:", value);
        setVerified(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        // e.target.reset();
        // setFormErrors(false);
        // setFormValues(true);


        //   emailjs.sendForm('service_ymarvgj', 'template_1g1fdio', e.target, 'user_NiL8Bf14JPlgveD96mnuV')
        //   .then((result) => {
        //       console.log(result.text);
        //   }, (error) => {
        //       console.log(error.text);
        //   });
    };


    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            console.log(formValues);
        }
    }, [formErrors])

    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /^[6-9]\d{9}$/;

        if (!values.name) {
            errors.name = "please enter your name";
        }
        if (!values.email) {
            errors.email = "please enter your email address";
        } else if (!regex.test(values.email)) {
            errors.email = "this is not a valid email format";
        }


        //  else if(!phone.test(values.phonenumber)){
        //      errors.phonenumber = "please enter your valid phone number";
        //  }
        if (!values.message) {
            errors.message = "please enter your message";
        }
        // if(values.recaptcha == ""){
        //     errors.recaptcha = "The Captcha field cannot be blank. Please enter a value";
        // }

        return errors;
    };

    
      
     
      


    return (
        <div>
            <div className='std-banner'>
                <Container fluid className=''>
                    <Row >
                        <Col >

                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='std-bg-img pt-5 pb-3'>
                <Container className=" mb-5">
                    <Row>
                        <Col className="text-center mt-5 std-box">
                            <h1>1 MONTH EXTENSIVE TRAINING</h1>
                            <p>D3M as a leading Digital marketing institute provides 100% internship assistance to our trainees.Fast-track instructor-led online Digital Marketing training, 100% practical implementation taught by industry experts to help you start your successful career in the Digital space</p>
                        </Col>
                        <Col className="text-center mt-5  std-box">
                            <h1 >ENTRANCE BASED TRAINING PROGRAMME</h1>
                            <p >We train you for 1 month hands on, in digiDZN office, under guidance of industry experts who are working real time on actual clients For next two months you are on paid internship handling client accounts under the guidance of our digital marketing agency. We train only 3 students per month.</p>
                        </Col>
                        <Col className="text-center mt-5  std-box">
                            <h1>HIGH QUALITY TRAINING</h1>
                            <p>Get confirmed paid 2 months internship with 1 month extensive digital marketing training. Our guidance, experience, and practical teaching techniques will help you to deal with real clients .</p>
                        </Col>
                        <Row>
                            <Col>
                            </Col>
                            <Col className='text-center'>
                                <a href={Pdf} target="_blank"><button className="growth-button mt-2 mb-2">Full Growth Capabilities</button> </a>
                            </Col>
                            <Col>
                            </Col>
                        </Row>

                    </Row>
                </Container>
            </div>
            <div className='mt-5 '>
                <Container>
                    <Row>
                        <Col className="digital-page-sec1-heading text-center">
                            <h1>PROGRAM DETAILS</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container className='p-2 mt-5' >
                    <Row>


                        <Col className="icon-digi text-center ">
                            <FaUserClock className='std-icon'></FaUserClock>
                            <Figure.Caption className="std-caption">
                                Duration: 2 Months
                            </Figure.Caption>

                        </Col>
                        <Col className="icon-digi text-center">
                            <FaCalendarAlt className='std-icon'></FaCalendarAlt>
                            <Figure.Caption className="std-caption"> Days: Mon | Wednesday | Friday </Figure.Caption>
                        </Col>
                        <Col className="icon-digi text-center">
                            <FaProjectDiagram className=' std-icon'></FaProjectDiagram>
                            <Figure.Caption className="std-caption"> Get Live Project Experience</Figure.Caption>
                        </Col>
                        <Col className="icon-digi text-center">
                            <FaRegEdit className=' std-icon'></FaRegEdit>
                            <Figure.Caption className="std-caption">Get Internship ready in 1 month</Figure.Caption>
                        </Col>


                        <Row>

                            <Col className='text-center mt-5 mb-5'>
                                <a href={Pdf} target="_blank"><button className="growth-button mt-2 mb-2">Request a Call Back</button> </a>
                            </Col>

                        </Row>

                    </Row>
                </Container>
            </div>

            <div className='mb-5 mt-5'>
                <Container>
                    <Row>
                        <Col className='text-center testi-digi-slider'>
                            <Slider {...settings}>
                                <div >

                                    <Row>
                                        <Col md={1}>
                                        </Col>
                                        <Col md={10} className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    would like to recommend the D3M for anyone who wants to learn Digital Marketing. It is the best institute which provides classroom training. The best part is they provide 100% internship assistance.
                                                </p></em>
                                        </Col>
                                        <Col md={1}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                        </Col>
                                        <Col md={1} className="text-right">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi3}
                                                alt="Testi3"
                                            />

                                        </Col>
                                        <Col md={1} className='text-left testi-content'>
                                            <h6><b>Vishal</b></h6>
                                            <p>Trainee</p>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>


                                </div>
                                <div>
                                    <Row>
                                        <Col md={1}>
                                        </Col>
                                        <Col md={10} className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    Easy learning experience, extremely patient and knowledgeable staff. Updated with the latest changes occurring everyday. methods of teaching are simple to understand. Recommended to join this institute, both if looking for jobs in digital marketing, as well as for your personal business
                                                </p></em>
                                        </Col>
                                        <Col md={1}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                        </Col>
                                        <Col md={1} className="text-right">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi2}
                                                alt="Testi2"
                                            />

                                        </Col>
                                        <Col md={1} className='text-left testi-content'>
                                            <h6><b>Gurpreet</b></h6>
                                            <p>Ex Trainee</p>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col md={1}>
                                        </Col>
                                        <Col md={10} className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    My experience with digiDZN was outstanding. My mentors are very supportive & knowledgeable.
                                                    Have gained practical knowledge while working on real clients.
                                                </p></em>
                                        </Col>
                                        <Col md={1}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                        </Col>
                                        <Col md={1} className="text-right">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi1}
                                                alt="Testi1"
                                            />

                                        </Col>
                                        <Col md={1} className='text-left testi-content'>
                                            <h6><b>Rishab</b></h6>
                                            <p>Ex Trainee</p>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>
                                </div>

                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="">
                <Container className="gy-0" fluid>
                    <Row className='bg-black'>
                        {/* <Col md={}></Col> */}
                        <Col md={6} className="digi-contact-form mt-5 p-2" >

                            <Stdanimation />
                       

                            <p>
                                Venue Entrance exam at digiDZN, Lawrence Road office Date & Timings Everyday at 3 pm from 19 July to 24 JulyBatch Start Date 2nd August 2021
                            </p>
                            <p>
                                Course fees for the entire program of 3 months will be 25,000
                                2nd & 3rd month will be paid internship of 5,000 per month
                            </p>


                        </Col>
                        <Col md={5} className="contact-form-bg  ">
                            <h1>
                                Ready to chat with our mentors?
                            </h1>
                            {/* <pre>{JSON.stringify(formValues,undefined,2)}</pre> */}


                            <form className="contactform" onSubmit={handleSubmit}>
                                <input type='text'

                                    name="name"
                                    value={formValues.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                                <p className="mb-4">{formErrors.name}</p>
                                <input type="email"

                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    placeholder="email"
                                />
                                <p className="mb-4">{formErrors.email}</p>


                                <textarea
                                    className="mb-3"
                                    placeholder="message"
                                    name="message"
                                    value={formValues.message}
                                    onChange={handleChange}
                                >

                                </textarea>

                                <p className="mb-4">{formErrors.message}</p>
                                {/* <input
                                    type="date" 
                                           className="mb-3" 
                                           placeholder="message"
                                           name="phonenumber" 
                                           value={formValues.phonenumber}
                                           onChange={handleChange}
                                          /> */}
                                <p className="mb-4">{formErrors.phonenumber}</p>
                                {/* <p className="mb-4">{formErrors.recaptcha}</p>    */}
                                <button className="contact-submit-btn">send</button>
                            </form>
                            {Object.keys(formErrors).length === 0 && isSubmit ? (<Redirect to='/thank-you-page'  />): (<div></div>) }
                            {/* {Object.keys(formErrors).length === 0 && isSubmit ? (<p className='mr-6'>thanks for submitting</p>) : (<div></div>)} */}
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    )
}

export default Forstudent
