import React from 'react'
import {Row,Col,Container} from 'react-bootstrap';
import Footer from '../Components/Footer';
import Deco1 from '../asset/img/deco-result1.png'
import Deco2 from '../asset/img/deco-result2.png'
import Deco3 from '../asset/img/deco-result3.png'
import Deco4 from '../asset/img/deco-result4.png'

const Maven = () => {
    return (
        <div>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <div className='space-1 mt-10'>
                                    </div>
                                
                                    <div className='deco-box1-edit'>
                                        <h1>
                                        WHO IS-
                                        </h1>
                                        <p>Deco-Arte is an interior designing studio that provides complete interior design solutions for restaurants, offices, hotels, homes, and retail spaces.</p>
                                    </div>
                            </div>   
                        </Col>
                        <Col>
                            <div>
                                <div className='space-2 mt-10'>
                                    </div>
                                    
                                    <div className="deco-box2-edit">
                                        <h1>
                                        Objective -
                                        </h1>
                                        <p>To create a visually appealing Instagram that stands out from the crowd and to generate leads through Social Media Platforms. Adding followers from their target market to their social channels</p>
                                    </div>
                                   
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
             {/* section2 maven    */}
             <div className="sec2-maven-bg mt-5">
                 <Container>
                     <Row>
                         <Col className="challenges-sec">
                         <h1>Challenges -</h1>
                         <p>i) The Interior Designing niche is very competitive with a lot of daily content being published by freelancers, agencies, magazine publications, interior design studios, influencers & even the everyday user showcasing their new design house.</p>
                         <p>ii) to create aesthetically & visually appealing content while still keeping the authenticity of the original work intact, after all the clients are DESIGNER themselves.</p>
                         <p>iii) Generate Leads in a highly competitive market</p>
                         </Col>
                     </Row>
                 </Container>

             </div>
             {/* section3 maven */}
             <div className="">
                 <Container>
                     <Row>
                         <Col className="deco-Approach-sec">
                         <h1>Our Approach-</h1>
                         <p>i) Content - Picking up references & inspiration from the Client’s work to align our creativity with theirs</p>
                         <p>ii) Daily Organic Posting: 1 Post & 3 Stories every day for all days of the week.</p>
                         <p>iii) A set marketing budget for each of the Client’s expectations in terms of Followers on all social media platforms and aligning it with</p>
                         </Col>
                     </Row>
                 </Container>

             </div>
            {/* section4 deco */}
            <div>
                <Container>
                    <Row>
                        <Col md={3}>

                        </Col>
                        <Col md={6}>
                        <div className='deco-box1-edit'>
                                        <h1>
                                        Objective -
                                        </h1>
                                        <p>Deco-Arte is an interior designing studio that provides complete interior design solutions for restaurants, offices, hotels, homes, and retail spaces.</p>
                                    </div>
                        </Col>
                        <Col md={3}>
                        </Col>
                    </Row>
                </Container>
            </div>

             {/* section4 maven */}
             <div className="mt-5">
                 <Container>
                     <Row>
                         <Col className="deco-Approach-sec">
                         <h1>Results-</h1>
                         
                         
                         </Col>
                     </Row>
                 </Container>
                 <Container>
                     <Row>
                         <Col className="deco-result-img">
                         
                                     <img
                                    className="d-block mt-5"
                                    width="100%"
                                    src={Deco1}
                                    alt="Sec-10"
                                    />
                                     <img
                                    className="d-block mt-5"
                                    width="100%"
                                    src={Deco2}
                                    alt="Sec-10"
                                    />
                                     <img
                                    className="d-block mt-5"
                                    width="100%"
                                    src={Deco3}
                                    alt="Sec-10"
                                    />
                                     <img
                                    className="d-block mt-5 mb-5"
                                    width="100%"
                                    src={Deco4}
                                    alt="Sec-10"
                                    />
                         
                         </Col>
                     </Row>
                 </Container>


             </div>

            <Footer/>
        </div>
    )
}

export default Maven
