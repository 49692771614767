import React from 'react'
import Footer from '../Components/Footer';
import D3img from '../asset/img/D3.png'
import { useState, useEffect } from 'react';
import Digital1 from '../asset/img/digital1.png'
import { Row, Col, Container, Figure } from 'react-bootstrap';
import Sec1 from '../asset/img/logo-1-1.png'
import Sec2 from '../asset/img/sec3.png'
import Sec3 from '../asset/img/logo2-1.png'
import Client1 from '../asset/img/Amardeep.webp'
import Slider from "react-slick";
import emailjs from 'emailjs-com';
import  { Redirect } from 'react-router-dom'
import $ from 'jquery';
import Testi1 from '../asset/img/testi1.jpg'
import Testi2 from '../asset/img/testi2.jpg'
import Testi3 from '../asset/img/worker4.jpg'
import { FaUserClock, FaProjectDiagram, FaMoneyCheck, FaUserAlt, FaLongArrowAltRight } from 'react-icons/fa'
function Digitalmarketinginternship() {
    const settings = {
        dots: true,
        // margin:10,
        arrows: false,
        infinite: true,
        speed: 500,
        adaptiveHeight: true,
        // autoplay: true,
        slidesToShow: 1,
        
    };

    const initialValues = { name: null, email: null, phonenumber: null, message: null, recaptcha: null };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isVerified, setVerified] = useState(null);
    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

    };



    // if (this.state.verified) {
    //     fetch()
    //     .then(this.handleSuccess)
    //     .catch(error => alert(error))
    //     .preventDefault()
    // }
    // else {this.setState({reply: "Please verify the ReCaptcha."})}
    React.useEffect(() => {
        // register({ name: "spam" }, { required: "prove you are no bot plz!" });
    }, []);

    const handleRecaptcha = value => {
        console.log("Captcha value:", value);
        setVerified(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);


        emailjs.sendForm('service_ymarvgj', 'template_24o1nkk', e.target, 'user_NiL8Bf14JPlgveD96mnuV')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        //   setFormErrors(false);
        //   setFormValues(true);

        //   document.getElementById("create-course-form").reset();
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            console.log(formValues);
        }
    }, [formErrors])

    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /^[6-9]\d{9}$/;

        if (!values.name) {
            errors.name = "please enter your name";
        }
        if (!values.email) {
            errors.email = "please enter your email address";
        } else if (!regex.test(values.email)) {
            errors.email = "this is not a valid email format";
        }

        if (!values.phonenumber) {
            errors.phonenumber = "please select Phone number";
        }
        else if(!phone.test(values.phonenumber)){
            errors.phonenumber = "please enter your valid phone number";
        }
        if (!values.message) {
            errors.message = "please enter your message";
        }
        // if(values.recaptcha == ""){
        //     errors.recaptcha = "The Captcha field cannot be blank. Please enter a value";
        // }

        return errors;
    };



    useEffect(() => {
        setInterval(function () {
            // alert("ok");
            // toggle the class every five second
            // $('#donate').toggleClass('e-hide-highlight');  
            setTimeout(function () {
                // toggle back after 1 second
                $('#donate').toggleClass('e-hide-highlight');
            }, 1000)

        }, 3000);
    }, [])

    return (
        <div>
           <a href='#digi-contact-form'>   <div style={{ width: '100%' }} className='digi-banner'>
                <img
                    className="d-block "
                    src={D3img}
                    width="100%"
                    alt="D3img"
                />
            </div>
            </a>
            <div>
                <Container>
                    <Row>
                        <Col className="digital-page-sec1-heading">
                            <h1>PROGRAM DETAILS</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container className='p-2'>
                    <Row>
                        <Col md={8}>
                            <Row xs="2" className='text-center padding-icons'>
                                <Col className="icon-digi">
                                    <FaUserClock className='digital-icon-edit'></FaUserClock>
                                    <Figure.Caption className="mt-4 figure-digi">
                                    Duration: 1 month Practical Training + 2 Months Paid Internship
                                    </Figure.Caption>

                                </Col>
                                <Col className="icon-digi">
                                    <FaProjectDiagram className='digital-icon-edit'></FaProjectDiagram>
                                    <Figure.Caption className="mt-4 figure-digi"> Get Live Project Experience </Figure.Caption>
                                </Col>
                                <Col className="icon-digi mt-5">
                                    <FaMoneyCheck className='digital-icon-edit'></FaMoneyCheck>
                                    <Figure.Caption className="mt-4 figure-digi"> Program Fees - Rs.25000 (incl GST)</Figure.Caption>
                                </Col>
                                <Col className="icon-digi mt-5">
                                    <FaUserAlt className='digital-icon-edit'></FaUserAlt>
                                    <Figure.Caption className="mt-4 figure-digi">Get back upto Rs.10000 in 2 months paid internship</Figure.Caption>
                                </Col>

                            </Row>
                        </Col>
                        <Col md={4} className="digi-sec1-p">
                            <p>Learn the fundamentals of digital marketing. There are individual modules for each topic that you can explore. All created by Industry expert trainers and are packed with practical exercises and real-world work, these modules will help you put your knowledge into practice.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-10">
                <Container>
                    <Row>
                        <Col md={6} className="digi-sec2-para" >
                            {/* <h1 className='mt-2 p-3'>digiDZN</h1> */}
                            <p className='mt-2 p-3'>
                               <u ><h6><b>First month</b></h6> </u>
                        
                                100+ hours of extensive readings & regular assessments
                                80+ hours of hands on training in digiDZN office
                            </p>
                            <p className='mt-2 p-3 ' style={{ paddingRight: "60px" }}>
                            <u ><h6><b>Second month</b></h6> </u>
                                120+ hours of working real time on live projects
                                Paid internship of upto Rs.5000 per month
                            </p>
                            <p className='mt-2 p-3'>
                            <u ><h6><b>Third month</b></h6> </u>
                                Paid Internship of upto Rs5000 per month
                                Complete Campaign Execution & Monitoring
                                Interaction With Clients & Workflow Management
                            </p>
                            <Row>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec1}
                                        alt="Sec1"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec2}
                                        alt="Sec2"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec3}
                                        alt="Sec3"
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6}>
                            <img
                                className="d-block img-icon text-center"

                                src={Digital1}
                                alt="Icon7"
                            />
                        </Col>
                    </Row>
                </Container>

            </div>
            <div className='gradiant'>
                <Container>
                    <Row>
                        <Col className='sec3-digi text-center'>
                            <h1>India's Leading <br />Digital Marketing Academy</h1>
                            <p>Boost Your Success</p>

                            <a href='#digi-contact-form'>     <button className='register-btn-digi text-center mt-3'>Register for Entrance Exam <FaLongArrowAltRight></FaLongArrowAltRight></button></a>


                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='sec4-digi'>
                <Container className=" mb-5">
                    <Row>
                        <Col className="text-center mt-5 digi-sec4-bx">
                            <h1>WE WILL<br /> FIND YOU</h1>
                            <p>We intake the students basis entrance examination as we train only 3 students per month. It is utmost priority that students learn by doing instead of just learning the concepts theoretically. We wish to take on board students who are serious and hence the need for an entrance test</p>
                        </Col>
                        <Col className="text-center mt-5  digi-sec4-bx">
                            <h1 >WE WILL <br /> TRAIN YOU</h1>
                            <p >We train you for 1 month hands on, in digiDZN office, under guidance of industry experts who are working real time on actual clients. This is in addition to extensive reading material we assign you as home works. You are assessed by these mentors on each module continuously. This one month training makes you ready for the 2 months paid internship that follows.</p>
                        </Col>
                        <Col className="text-center mt-5  digi-sec4-bx">
                            <h1>WE WILL<br />  PLACE YOU</h1>
                            <p>For next two months you are on paid internship handling client accounts under the guidance of our digital marketing agency. Assessment scores in the training month decides the stipend you get. You get Rs.5000 stipend pm for 2 months if your assessment score is more than 80%. It reduces if your assessment scores fall, minimum stipend being Rs.3000pm.</p>
                        </Col>
                        <Row>
                            <Col>
                            </Col>
                            <Col className='text-center'>
                              <a href='#digi-contact-form'> <button className='register-btn-digi2 text-center mt-3 mb-5'>Register Now <FaLongArrowAltRight></FaLongArrowAltRight></button></a>
                            </Col>
                            <Col>
                            </Col>
                        </Row>

                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col className='text-center testi-digi'>
                            <h1>
                                SUCCESS STORIES
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='mb-5 mt-5'>
                <Container>
                    <Row>
                        <Col className='text-center testi-digi-slider'>
                            <Slider {...settings}>
                                <div >

                                    <Row>
                                        <Col md={1} xs={12} sm={12}>
                                        </Col>
                                        <Col md={10} xs={12} sm={12} className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    would like to recommend the D3M for anyone who wants to learn Digital Marketing. It is the best institute which provides classroom training. The best part is they provide 100% internship assistance.
                                                </p></em>
                                        </Col>
                                        <Col md={1} xs={12} sm={12}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5} xs={12} sm={12}>
                                        </Col>
                                        <Col md={1} xs={12} sm={12} className="text-right">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi3}
                                                alt="Testi3"
                                            />

                                        </Col>
                                        <Col md={1} xs={12} sm={12} className='text-left testi-content'>
                                            <h6><b>Vishal</b></h6>
                                            <p>Trainee</p>
                                        </Col>
                                        <Col md={4} xs={12} sm={12}>
                                        </Col>
                                    </Row>


                                </div>
                                <div >
                                    <Row>
                                        <Col md={1}>
                                        </Col>
                                        <Col md={10} className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    Easy learning experience, extremely patient and knowledgeable staff. Updated with the latest changes occurring everyday. methods of teaching are simple to understand. Recommended to join this institute, both if looking for jobs in digital marketing, as well as for your personal business
                                                </p></em>
                                        </Col>
                                        <Col md={1}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                        </Col>
                                        <Col md={1} className="text-right">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi2}
                                                alt="Testi2"
                                            />

                                        </Col>
                                        <Col md={1} className='text-left testi-content'>
                                            <h6><b>Gurpreet</b></h6>
                                            <p>Ex Trainee</p>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>
                                </div>
                                
                                <div>
                                    <Row>
                                        <Col md={1}>
                                        </Col>
                                        <Col md={10} className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    My experience with digiDZN was outstanding. My mentors are very supportive & knowledgeable.
                                                    Have gained practical knowledge while working on real clients.
                                                </p></em>
                                        </Col>
                                        <Col md={1}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                        </Col>
                                        <Col md={1} className="text-right bg-transparent">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi1}
                                                alt="Testi1"
                                            />

                                        </Col>
                                        <Col md={1} className='text-left testi-content'>
                                            <h6><b>Rishab</b></h6>
                                            <p>Ex Trainee</p>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>
                                </div>

                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* mobile testimonial d3academy */}
            {/* <div className='mb-5 mt-5'>
                <Container>
                    <Row>
                        <Col className='text-center testi-digi-slider'>
                            <Slider {...settings}>
                                <div >

                                    <Row >
                                        
                                        <Col md={12} xs={12} sm={12}  className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    would like to recommend the D3M for anyone who wants to learn Digital Marketing. It is the best institute which provides classroom training. The best part is they provide 100% internship assistance.
                                                </p></em>
                                        </Col>
                                        
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                        
                                        <Col md={1} xs={12} sm={12} className="text-right">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi3}
                                                alt="Testi3"
                                            />

                                        </Col>
                                        <Col md={1} xs={12} sm={12} className='text-left testi-content '>
                                            <h6><b>Vishal</b></h6>
                                            <p>Trainee</p>
                                        </Col>
                                        
                                    </Row>


                                </div>
                                <div>
                                    <Row>
                                        
                                        <Col md={10}   className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    Easy learning experience, extremely patient and knowledgeable staff. Updated with the latest changes occurring everyday. methods of teaching are simple to understand. Recommended to join this institute, both if looking for jobs in digital marketing, as well as for your personal business
                                                </p></em>
                                        </Col>
                                        
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                        
                                        <Col md={1} className="text-right">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi2}
                                                alt="Testi2"
                                            />

                                        </Col>
                                        <Col md={1} className='text-left testi-content'>
                                            <h6><b>Gurpreet</b></h6>
                                            <p>Ex Trainee</p>
                                        </Col>
                                        
                                    </Row>
                                </div>
                                
                                <div>
                                    <Row>
                                        
                                        <Col md={10}   className='text-center testi-digi-slide hero mb-5'>
                                            <em>
                                                <p>
                                                    My experience with digiDZN was outstanding. My mentors are very supportive & knowledgeable.
                                                    Have gained practical knowledge while working on real clients.
                                                </p></em>
                                        </Col>
                                        
                                    </Row>
                                    <Row className="">
                                        
                                        <Col md={1} className="text-right bg-transparent">
                                            <img
                                                className="d-block rounded-circle testi-img center"
                                                width="60"
                                                src={Testi1}
                                                alt="Testi1"
                                            />

                                        </Col>
                                        <Col md={1} className='text-left testi-content'>
                                            <h6><b>Rishab</b></h6>
                                            <p>Ex Trainee</p>
                                        </Col>
                                        
                                    </Row>
                                </div>

                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            
            {/* form for d3academy */}
            <div className="" id="digi-contact-form">
                <Container className="gy-0" fluid>
                    <Row >
                        {/* <Col md={}></Col> */}
                        <Col md={6} className="digi-contact-form mt-5 p-5" >

                            <div className="elementor-element elementor-element-14e75fc5 elementor-headline--style-highlight elementor-widget elementor-widget-animated-headline" data-element_type="widget" data-id="14e75fc5" data-settings="{&quot;highlighted_text&quot;:&quot;Important Dates For August Batch&quot;,&quot;headline_style&quot;:&quot;highlight&quot;,&quot;marker&quot;:&quot;circle&quot;,&quot;loop&quot;:&quot;yes&quot;,&quot;highlight_animation_duration&quot;:1200,&quot;highlight_iteration_delay&quot;:8000}" data-widget_type="animated-headline.default">
                                <div className="elementor-widget-container">
                                    <h3 id="donate" className="elementor-headline e-animated">
                                        <span className="elementor-headline-dynamic-wrapper elementor-headline-text-wrapper">
                                            <span className="elementor-headline-dynamic-text elementor-headline-text-active">Important Dates </span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none">
                                                <path d="M325,18C228.7-8.3,118.5,8.3,78,21C22.4,38.4,4.6,54.6,5.6,77.6c1.4,32.4,52.2,54,142.6,63.7 c66.2,7.1,212.2,7.5,273.5-8.3c64.4-16.6,104.3-57.6,33.8-98.2C386.7-4.9,179.4-1.4,126.3,20.7"></path>
                                            </svg>
                                        </span>
                                    </h3>
                                </div>
                            </div>

                            <p>
                                Venue of Entrance Exam: digiDZN, 1st floor, G-4, Lawrence Road Industrial Area, Delhi - 110035
                            </p>
                            <p>
                                Timings: Everyday at 1 PM from August 19, 2021 to August 24, 2021
                            </p>
                            <p>
                                Course Fees for 3 months program: Rs.25000 (GST inclusive)
                            </p>
                            <p>
                                Internship upto Rs.5000 per month (In 2nd & 3rd month of the program)
                            </p>
                            <p>Please Contact - 9289745760</p>

                        </Col>
                        <Col md={6} className="contact-form-bg mt-5">
                            <h1>
                                Register Now <br />For The Entrance Exam.
                            </h1>
                            {/* <pre>{JSON.stringify(formValues,undefined,2)}</pre> */}


                            <form className="contactform" onSubmit={handleSubmit} id="create-course-form">
                                <input type='text'

                                    name="name"
                                    value={formValues.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                                <p className="mb-4">{formErrors.name}</p>
                                <input type="email"

                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    placeholder="email"
                                />
                                <p className="mb-4">{formErrors.email}</p>


                                <textarea
                                    className="mb-3"
                                    placeholder="message"
                                    name="message"
                                    value={formValues.message}
                                    onChange={handleChange}
                                >

                                </textarea>

                                <p className="mb-4">{formErrors.message}</p>
                                <input
                                    type="tel"
                                    className="mb-3"
                                    placeholder="Phone Number"
                                    name="phonenumber"
                                    value={formValues.phonenumber}
                                    onChange={handleChange}
                                />
                                <p className="mb-4">{formErrors.phonenumber}</p>
                                {/* <p className="mb-4">{formErrors.recaptcha}</p>    */}
                                <button className="contact-submit-btn">Send</button>
                            </form>
                            {/* {Object.keys(formErrors).length === 0 && isSubmit ? (<p className='mr-6'>thanks for submitting</p>) : (<div></div>)} */}
                            {Object.keys(formErrors).length === 0 && isSubmit ? (<Redirect to='/thank-you-page'  />): (<div></div>) }
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    )
}

export default Digitalmarketinginternship
