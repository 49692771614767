import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import { Row, Col, Container, Card } from 'react-bootstrap';
import Seo1 from '../asset/img/seo1.png'
import Seo2 from '../asset/img/seo2.png'
import Seo3 from '../asset/img/seo3.png'
import Result1 from '../asset/img/sec-10.jpg'
import Result2 from '../asset/img/sec-11.jpg'
import Blogimg1 from '../asset/img/blog-img1.png'
import Blogimg2 from '../asset/img/blog-img2.jpg'
import Blogimg3 from '../asset/img/blog-img3.png'
import Footer from '../Components/Footer';
import Testimonial from './testimonial';
import { Helmet } from 'react-helmet-async';
import TestimonialNew from './testimonial_new';
import Carousel1 from '../Components/carousel1';
function socialmedia() {
    return (
        <div>
            <Helmet>
                <title>SEO Company in Delhi | SEO Agency | DigiDZN</title>
                <meta name="description" content="More Traffic! More Leads! More Everything! · Delivering Organic Growth with SEO Agency in Delhi. Help small businesses improve their online presence in search and social media in order to acquire new customers." />
            </Helmet>
            <div className="social-banner">
                <Container>
                    <Row>
                        <Col className="social-heading mt-5">
                            <h2>
                                Strategy SEO to Power your Business
                            </h2>
                            <h6 className="mt-4">Search Engine Optimization is an evolving science to improve your page rank. <br />
                                SEO is a process of increasing the visibility of the website in a web search engine organically.</h6>

                        </Col>

                    </Row>
                </Container>
            </div>
            {/* section 2 social media */}
            <div className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xs={12} >
                            <img
                                className="d-block social-media "
                                width="125"
                                src={Seo1}
                                alt="Social2"
                            />
                        </Col>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                                On-Page Optimization
                            </h1>
                            <p>

                                The role here is played by Meta tags, Meta description,
                                page title, URL structure, keyword density and H1 – H6 tags.
                                Once you optimize them frequently, then you can improve the search
                                ranking results of your website on the search engine as well.
                                To get more relevant traffic on your web pages, we highly recommend
                                On-Page optimization activities while keeping up with the latest trends and updates.

                            </p>
                            <Link to="./contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section2 */}

            {/* section2 */}
            <div className="mt-10 desktop-tracking">
                <Container>
                    <Row>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                                Off-Page Optimization
                            </h1>
                            <p>

                                The role here is played by link building, PR, blog submission,
                                social networking sites, bookmarking and so forth. A right strategy
                                and approach to execute the off-page optimization can definitely
                                improve the search rankings. A website's ability to rank is equally
                                dependent on Off-page optimization activities. With trustworthiness,
                                authority and relevance factors, it is a quick and easy way to rank!

                            </p>
                            <Link to="./contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                        <Col md={6} xs={12}>
                            <img
                                className="d-block social-media "
                                width="125"
                                src={Seo2}
                                alt="Social3"
                            />

                        </Col>
                    </Row>
                </Container>
            </div>
            {/* mobile responsive */}

            <div className="mt-10 mob-res-tracking">
                <Container>
                    <Row>
                        <Col md={6} xs={12} className="social-section">

                            <img
                                className="d-block social-media "
                                width="125"
                                src={Seo2}
                                alt="Social3"
                            />

                        </Col>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                                Off-Page Optimization
                            </h1>
                            <p>

                                The role here is played by link building, PR, blog submission,
                                social networking sites, bookmarking and so forth. A right strategy
                                and approach to execute the off-page optimization can definitely
                                improve the search rankings. A website's ability to rank is equally
                                dependent on Off-page optimization activities. With trustworthiness,
                                authority and relevance factors, it is a quick and easy way to rank!

                            </p>
                            <Link to="./contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>


            {/*  */}
            {/* section3 */}
            <div className="mt-5 mb-5">
                <Container>
                    <Row>
                        <Col md={6} xs={12}>
                            <img
                                className="d-block social-media "
                                width="125"
                                src={Seo3}
                                alt="Social1"
                            />
                        </Col>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                                Keywords Strategy
                            </h1>
                            <p>

                                How to curate the content that can satisfy your user along
                                with the search engines? Well, it is through excellent keyword strategy.
                                Keyword research is primarily based on the target market,
                                and what they are searching for, be it in content, products or services!
                                We follow the right strategy and tactics for keyword research utilizing
                                the data and audience.

                            </p>
                            <Link to="./contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* testimonial           */}
            {/* <Testimonial /> */}
            <TestimonialNew />
            {/* section4 */}

            {/* <div className="bg-result mt-1 pb-5">
            <Container>
                <Row>
                    <Col className="text-center mt-1 heading-full-growth">
                    <h1>
                    Consistently Delivering Results
                    </h1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className="mt-5 ">
                        <div className="bgcolor-result"> 
                                    <img
                                    className="d-block img-result border-result"
                                    src={Result1}
                                    alt="Sec-10"
                                    />
                        </div>
                    </Col>
                    <Col className="mt-5">
                        <div className="bgcolor-result">
                                   <img
                                    className="d-block border-result img-result"
                                    src={Result2}
                                    alt="Sec-10"
                                    />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-5">
                    <Link to="/results">  <button className="growth-button">Read more</button></Link>
                    </Col>
                </Row>
            </Container>
            
        </div> */}

            {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
            {/* <div class="custom-shape-divider-top-1635236144 mt-5">
                <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div> */}
            {/* <div className="ready-kick-bg">
    
            <Container >
                <Row>
                    <Col className="text-center sec-6-head">
                    <h1>Ready To Kick Off Your Growth Journey</h1>
                    <Link to="./contact-us"><button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                    </Col>

                </Row>
            </Container>
        </div> */}
        <Carousel1 />
            <div className="ready-kick-bg mt-5 yellow-bg">

                <Container >
                    <Row>
                        <Col className="text-center sec-6-head mt-4">
                            <h1 className='h4'>Ready To Kick Off Your<br></br> Growth Journey? 🚀</h1>
                            <Link to="./contact-us"> <button className="mt-2 button-custom">SPEAK TO DIGIDZN</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div>
            <Footer />

        </div>
    )
}

export default socialmedia
