export const emailValidator = function (value) {
    const email_Regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
     if (email_Regex.test(value)) {
      return true
    }
    return false
  }

  export const valueEmptyCheck = function (value) {
    if (value?.trim()?.length === 0) {
      return false
    }
    return true
  }

  export const validateWebsite = function(value){
    const website_Regex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm); 
    if(website_Regex.test(value)){
        return true
    }
    return false
  }