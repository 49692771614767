import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory, Link } from "react-router-dom";
import { Row, Col, Container, Figure, Card } from 'react-bootstrap';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import './blog.css'
import User from '../asset/img/user.png';
import Blogimg1 from '../asset/img/blog-img1.png';
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import { postRequest, MD5Hash, BLOG_LIST, BASE_IMAGE_URL, MASTER_IMAGE_URL } from '../util/api';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Moment from 'moment';
import { Helmet } from 'react-helmet-async';

const axios = require('axios');

const Blog = () => {
    const { city } = useParams();
    const [bloglist, setBlogList] = useState([]);
   
    const [blogPagination, setBlogPagination] = useState({
        page: 1
    });
    const [disableNextBtn, setDisableNextBtn] = useState(true)
    const history = useHistory()
    let content = null;
    useEffect(() => {
        blogList();
    }, [])

    const blogList = async () => {

        let auth_token = MD5Hash('');
        let data = {
            "auth_token": auth_token,
            "page": blogPagination.page
        }
        var res = await postRequest(BLOG_LIST, data);
        // debugger;
        if (res.success) {
            setBlogList(res?.parameters?.data);
            setDisableNextBtn(true)
        } else {
            setDisableNextBtn(false)
        }
    }
    // console.log(bloglist);

    const navigate = (blog_id) => {
        console.log(blog_id);
        // localStorage.blogItem('blog_id',blog_id);
        // debugger; 

    }

    function handlePrevious() {
        blogPagination.page = blogPagination.page - 1
        blogList();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function handleNext() {
        // setBlogPagination({
        //     ...blogPagination,
        blogPagination.page = blogPagination.page + 1
        // });

        blogList();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }


    return (
        <div>
            <Helmet>
                <title>Best Digital Marketing Agency in Delhi, NCR | digiDZN</title>
                <meta name="description" content="Welcome to digiDZN, a leading agency for digital marketing company in Delhi. We utilize our digital marketing experience and world-class knowledge to help our customers to grow in their industry." />
            </Helmet>
            <div>
                <Container>
                    <Row>
                        <Col className='mt-10'>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='blog-size'>
                <Row className="mt-5">
                    {
                        bloglist.map((blog) =>
                            {
                            // debugger;
                            // console.log(blog, 'Testing....')
                            const regex = /(<([^>]+)>)/ig;
                            let [year, month, dates] = blog.start_datetime.split("-");
                            let months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

                            let values = `${months[parseInt(month) - 1]} ${dates.split(" ")[0]} ${year}`;
                            // console.log("values :", values);
                            console.log(blog.seo_url);
                            return (
                                <Col md={4} xs={12} >
                                    <Card className="text-center mt-3 rounded card-blog">
                                        <span className='catgory-blog'>{blog.blogCategories[0].category_name}</span>
                                        {
                                            blog.blog_image_url != null ? (
                                                <Card.Img variant="top"
                                                    className="blog-img border img-fluid"
                                                    src={`${BASE_IMAGE_URL}${blog.blog_image_url}`} />
                                            ) : (
                                                <div className='no-img-div'>
                                                    No image
                                                </div>
                                            )
                                        }

                                        <Card.Body>
                                            <Card.Img
                                                className='rounded-circle wid-blog'
                                                src={`${MASTER_IMAGE_URL}${blog.master_profile}`}
                                                alt='user' />
                                            <Card.Title className='blog-title mt-4'>{blog.blog_name}</Card.Title>
                                            <Card.Text className="mt-4">
                                                {/* <div>{blog.description.replace(regex, '').slice(0,150)}</div> */}
                                                <div>{ReactHtmlParser(blog?.description)}</div>
                                            </Card.Text>
                                            {/* onClick={() => navigate(blog.blog_id)} */}
                                            <Link className="read-more-txt" to={`/blog-view/${blog.seo_url}`} >Read More</Link>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">
                                            <span className="text-muted footer-list-blog text-capitalize">{values}</span>
                                            <span>•</span>

                                            <span className="text-muted footer-list-blog ">Comments : {blog.blogCommentsCount}</span>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            )

                        })

                    }
                    <div className='pagination-div'>
                        <button className='preview-btn' disabled={blogPagination.page <= 1} type='button' onClick={handlePrevious}> {"<< Previous"}</button>
                        <button className='next-btn' disabled={disableNextBtn == false} type='button' onClick={handleNext}>{"Next >>"}</button>
                    </div>
                </Row>

            </Container>

            <Footer />
        </div>

    )
}
export default Blog;

