import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import fam1 from '../asset/img/fam1.png'
import fam2 from '../asset/img/fam2.png'
import fam3 from '../asset/img/fam3.png'
import fam4 from '../asset/img/fam4.png'
import fam5 from '../asset/img/fam5.png'
import fam6 from '../asset/img/fam6.png'
import fam7 from '../asset/img/fam7.png'
import fam8 from '../asset/img/fam8.png'
import fam9 from '../asset/img/fam9.png'
import Deco2 from '../asset/img/deco-result2.png'
import Deco3 from '../asset/img/deco-result3.png'
import Deco4 from '../asset/img/deco-result4.png'
import Footer from '../Components/Footer';
const Famphy = () => {
    return (
        <div>

            <div >
                <Container>
                    <Row>
                        <Col>
                            <div className='mt-6'>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='famphy-banner'>

            </div>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <div className='space-1 '>
                                </div>

                                <div className='deco-box1-edit'>
                                    <h1>
                                        WHO IS-
                                    </h1>
                                    <p>Famphy provides a doctor on call for non-critical illness. All you’ve got to do is call their helpline & book an appointment. Once the appointment is confirmed a qualified doctor will visit your place shortly within the given time..</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <div className='space-2 '>
                                </div>

                                <div className="deco-box2-edit">
                                    <h1>
                                        Objective -
                                    </h1>

                                    <p>The Client’s primary goal was to increase traffic to the website without spending aggressively on paid marketing..</p>
                                    <div class="height-famphy"></div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section2 maven    */}
            <div className="sec2-maven-bg mt-5">
                <Container>
                    <Row>
                        <Col className="challenges-sec">
                            <h1>Challenges -</h1>
                            <p>i) The Client’s business is a necessity-based service & the conversion occurs only on the keywords with high competition.</p>
                            <p>ii) The challenge was to drive organic traffic to the website on keywords where big industry players were spending aggressively.</p>
                            <p>iii) To establish the trust factor through our content & creatives for the user to pay an advance just by viewing our content. This was a major concern as in the health sector customers prefer to go to big brands known that resonate quality for decades.</p>
                            <p>iv)The competition had quality backlinks from years of collaboration with government bodies aiding to their higher relevance on client’s focus keywords.</p>
                        </Col>
                    </Row>
                </Container>

            </div>
            {/* section3 maven */}
            <div className="">
                <Container>
                    <Row>
                        <Col className="deco-Approach-sec deco-result-img ">
                            <h1>Our Approach-</h1>
                            <p>i) To establish trust & credibility we needed to rank on the focus keywords we needed to have good user activity on the website. For this, we needed quality content on trending user queries. We gave REAL SOLUTIONS to REAL PROBLEMS through our content.</p>
                            <img
                                className="d-block mt-5"
                                width="100%"
                                src={fam1}
                                alt="fam1"
                            />


                        </Col>
                    </Row>
                    <Row>
                        <Col className='deco-result-img d-flex justify-content-center'>
                            <img
                                className="d-block mt-5 "
                                width="70%"
                                src={fam2}
                                alt="fam1"
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col className="deco-Approach-sec ">
                            <p>ii) Once we had enough traffic coming to our website, we started with paid ads to start increasing the relevancy of the website for our primary keywords.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="deco-Approach-sec deco-result-img d-flex justify-content-center">


                            <img
                                className="d-block mt-5"
                                width="70%"
                                src={fam3}
                                alt="fam1"
                            />


                        </Col>
                    </Row>

                    <Row>
                        <Col className="deco-Approach-sec deco-result-img ">
                            <h1>Traffic Value-</h1>
                            <p>
                                did you know your traffic has a value depending on the traffic you receive on your website both in terms of quality & quantity.</p>
                            <img
                                className="d-block mt-5"
                                width="100%"
                                src={fam4}
                                alt="fam1"
                            />


                        </Col>
                    </Row>
                </Container>

            </div>
            {/* section4 deco */}


            {/* section4 maven */}
            <div className="mt-5 mb-5">
                <Container>
                    <Row>
                        <Col className="deco-Approach-sec">
                            <h1>Results-</h1>
                            <p>In just 6 months of concentrated efforts from all fronts, we started ranking on the 1st page for all our primary keywords & the best part is even after months of no SEO activity THEY STILL DO.</p>

                        </Col>
                    </Row>
                </Container>
                <Container>
                    

                    <Row>
                        <Col className='deco-result-img d-flex justify-content-center'>
                        <img
                                className="d-block mt-5"
                                width="80%"
                                src={fam5}
                                alt="Sec-10"
                            />

                        </Col>
                    </Row>
                    
                    <Row>
                        <Col className='deco-result-img d-flex justify-content-center'>
                        <img
                                className="d-block mt-5"
                                width="80%"
                                src={fam6}
                                alt="Sec-10"
                            />

                        </Col>
                    </Row>
                    
                    <Row>
                        <Col className='deco-result-img d-flex justify-content-center'>
                        <img
                                className="d-block mt-5"
                                width="80%"
                                src={fam7}
                                alt="Sec-10"
                            />

                        </Col>
                    </Row>
                    
                    <Row>
                        <Col className='deco-result-img d-flex justify-content-center'>
                        <img
                                className="d-block mt-5"
                                width="80%"
                                src={fam8}
                                alt="Sec-10"
                            />

                        </Col>
                    </Row>
                    
                    <Row>
                        <Col className='deco-result-img d-flex justify-content-center'>
                        <img
                                className="d-block mt-5"
                                width="80%"
                                src={fam9}
                                alt="Sec-10"
                            />

                        </Col>
                    </Row>
                </Container>


            </div>

            <Footer />
        </div>
    )
}

export default Famphy
