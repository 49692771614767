import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Figure, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import MoreDeets from "../pages/card";
import Digivid from '../Video/digidzn-vid.4ac3484e.gif'
import Caro1 from '../asset/img/1-6.webp'
import Caro2 from '../asset/img/2-5.webp'
import Caro3 from '../asset/img/3-4.jpg'
import Caro4 from '../asset/img/4-3.webp'
import Caro5 from '../asset/img/5-2.webp'
import Caro6 from '../asset/img/6-1.jpg'
import logo from '../asset/img/logo.png'
import Sec1 from '../asset/img/logo-1-1.png'
import Sec2 from '../asset/img/sec3.png'
import Sec3 from '../asset/img/logo2-1.png'
import Testimonial from './testimonial';
import TestimonialNew from './testimonial_new';
import Result1 from '../asset/img/sec-10.jpg'
import Result2 from '../asset/img/sec-11.jpg'
import Blogimg1 from '../asset/img/blog-img1.png'
import Blogimg2 from '../asset/img/blog-img2.jpg'
import Blogimg3 from '../asset/img/blog-img3.png'
import banner_bg from '../asset/img/flow.gif'
import line from '../asset/img/line.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from 'react-bootstrap/Button'
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import Footer from '../Components/Footer';
import Carousel1 from '../Components/carousel1';
import Animation from './Animation';
import { Helmet, HelmetProvider } from "react-helmet-async";
import './Home.css';
import LogoSliders from './LogoSlider';
import TextLoop from "react-text-loop";
import India from '../asset/img/map.svg'
import Accordion from 'react-bootstrap/Accordion';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import cxo from '../asset/img/CXO.png'
import inidatv from '../asset/img/Ndtv 2.png'
import times from '../asset/img/the.png'
import indian from '../asset/img/indian retailer.png'
import { postRequest, MD5Hash, BLOG_LIST, BASE_IMAGE_URL, MASTER_IMAGE_URL } from '../util/api';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
// import Map, { GeolocateControl } from "react-map-gl";
import clientMap from '../asset/img/map.webp'
// import "mapbox-gl/dist/mapbox-gl.css";
// import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';

const Home = () => {



    const [bloglist, setBlogList] = useState([]);
    const { name } = useParams();
    const [content, setContent] = useState("");
    const [disableNextBtn, setDisableNextBtn] = useState(true)
    const [blogPagination, setBlogPagination] = useState({
        page: 1
    });





    useEffect(() => {
        blogList();
    }, [])
    const blogList = async () => {

        let auth_token = MD5Hash('');
        let data = {
            "auth_token": auth_token,
            "page": blogPagination.page
        }
        var res = await postRequest(BLOG_LIST, data);
        // debugger;
        if (res.success) {
            setBlogList(res?.parameters?.data);
            setDisableNextBtn(true)
        } else {
            setDisableNextBtn(false)
        }
    }
    function handlePrevious() {
        blogPagination.page = blogPagination.page - 1
        blogList();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function handleNext() {
        // setBlogPagination({
        //     ...blogPagination,
        blogPagination.page = blogPagination.page + 1
        // });

        blogList();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        if (!name) {

        } else {
            setContent(name.substring(0));
        }
    }, [name]);

    console.log(content)
    const [words, setWords] = useState(["Sales", "Business", "Channel", "Conversions", "Userbase"]);


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoPlay: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const client = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (


        <div>
            {content ?
                <HelmetProvider>
                    <Helmet>
                        <title>Best Digital Marketing Agency in {content}, India | Creative Agency | digiDZN</title>
                        <meta name="description" content={`digiDZN is a leading digital marketing agency in  ${name}, India. We offer services like SEO, SMM, PPC, Influencer Marketing and more. `} />
                    </Helmet>
                </HelmetProvider>
                :
                <HelmetProvider>
                    <Helmet>
                        <title>Best Digital Marketing Agency in India | Creative Agency | digiDZN</title>
                        <meta name="description" content={`digiDZN is a leading digital marketing agency in India. We offer services like SEO, SMM, PPC, Influencer Marketing and more. `} />
                    </Helmet>
                </HelmetProvider>

            }

            {/* banner text and video */}
            <div className=" homepage-container1 ">
                <Container className='homepage-container1'>
                    <Row>
                        <Col xs={12} md={12} className="banner-text mt-150">
                            <h3 className="homepage-hero-section__title text-white line-height-1-0-5 mt-4">We Handhold Medium Sized  Businesses to Grow&nbsp;
                                <TextLoop interval={3000} delay={200} adjustingSpeed={200} fade={true} springConfig={{ stiffness: 180, damping: 17 }} className='font-custom me-3'>
                                    <h1 className='mb-0 pb-0 style_1'>Sales </h1>
                                    <h1 className='mb-0 pb-0 style_2'>Business </h1>
                                    <h1 className='mb-0 pb-0 style_3'>Channel </h1>
                                    <h1 className='mb-0 pb-0 style_4'>Conversions </h1>
                                    <h1 className='mb-0 pb-0 style_3'>Userbase </h1>
                                </TextLoop>
                                <br></br>
                                Through Online Mediums</h3>
                            {/* <p class="banner-desc mt-3 mb-0 ml-3">We have build scalable online business for ourselves. </p>
                            <p class="banner-desc mt-3 mb-0">We are committed to assist businesses explore  the power of internet to convert online mediums into a profitable distribution channel.</p> */}

                        </Col>
                    </Row>
                </Container>
                <Container fluid="p-0">
                    <img
                        className="d-block"
                        src={banner_bg}
                        alt="Icon1"
                        width="100%"
                    />
                </Container>

                {/* banner icon-section */}
                <LogoSliders />
            </div>

            <Carousel1 />

            <div>
                <div className='homepage-container1 pt-5 pb-0 '>

                    <Container>
                        <Row className='align-items-center'>
                            <Col md={12} className="mb-0 text-center">
                                <h1 className='text-white mt-2 mb-2 fw-bolder'>
                                    Client locations
                                </h1></Col>
                            <Col md={12} className="mb-5 text-center">
                                {/* <img className='px-2 map_img w-sm-50 w-md-50 w-lg-50' src={India} alt="india-map"/> */}
                                {/* <Map
                                    mapboxAccessToken="pk.eyJ1IjoidmliaWwiLCJhIjoiY2xqNnNsa2s3MHI2ejNycW5zaTM4MGdkcyJ9.O2pwkkUra8ZCE5SyvrfjNQ"
                                    initialViewState={{
                                        longitude: -100,
                                        latitude: 40,
                                        zoom: 3.5,
                                    }}
                                    mapStyle="mapbox://styles/mapbox/streets-v11"
                                >
                                    <GeolocateControl
                                        positionOptions={{ enableHighAccuracy: true }}
                                        trackUserLocation={true}
                                    />
                                </Map> */}



                            </Col>
                            <Col md={7} className="heading-full-growth locat_list-hide">
                                <h1 className='text-white mt-0 mb-2'>
                                    Client locations
                                </h1>
                                <div class="uvp-list">
                                    <ul className='px-3'>
                                        <Row>
                                            <Col>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Ahmedabad/digital-marketing-agency'>Ahmedabad</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Arunachal Pardesh/digital-marketing-agency'>Arunachal Pradesh</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Chennai/digital-marketing-agency'>Chennai</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Chhattisgarh/digital-marketing-agency'>Chhattisgarh</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Dehradun/digital-marketing-agency'>Dehradun</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Guwahati/digital-marketing-agency'>Guwahati</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Gorakhpur/digital-marketing-agency'>Gorakhpur</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Haryana/digital-marketing-agency'>Haryana</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Indore/digital-marketing-agency'>Indore</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Jodhpur/digital-marketing-agency'>Jodhpur</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/kochi/digital-marketing-agency'>kochi</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Lucknow/digital-marketing-agency'>Lucknow</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Mumbai/digital-marketing-agency'>Mumbai</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Noida/digital-marketing-agency'>Noida</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Odisha/digital-marketing-agency'>Odisha</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Rajasthan/digital-marketing-agency'>Rajasthan</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Thane/digital-marketing-agency'>Thane</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Uttarakhand/digital-marketing-agency'>Uttarakhand</Link></li>

                                            </Col>
                                            <Col>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Allahabad/digital-marketing-agency'>Allahabad</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Bangalore/digital-marketing-agency'>Bangalore</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Chandigarh/digital-marketing-agency'>Chandigarh</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Delhi/digital-marketing-agency'>Delhi</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Delhi ncr/digital-marketing-agency'>Delhi ncr</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Goa/digital-marketing-agency'>Goa</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Gurgaon/digital-marketing-agency'>Gurgaon</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Himachal Pardesh/digital-marketing-agency'>Himachal Pradesh</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Jharkhand/digital-marketing-agency'>Jharkhand</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Kanpur/digital-marketing-agency'>Kanpur</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Kerala/digital-marketing-agency'>Kerala</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Madhya Pardesh/digital-marketing-agency'>Madhya Pradesh</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Meghalaya/digital-marketing-agency'>Meghalaya</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Nagpur/digital-marketing-agency'>Nagpur</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Punjab/digital-marketing-agency'>Punjab</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Rajkot/digital-marketing-agency'>Rajkot</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Uttar pardesh/digital-marketing-agency'>Uttar Pradesh</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/vadodara/digital-marketing-agency'>vadodara</Link></li>
                                            </Col>
                                            <Col>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Andhra Pardesh/digital-marketing-agency'>Andhra Pradesh </Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Borivali/digital-marketing-agency'>Borivali</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Coimbatore/digital-marketing-agency'>Coimbatore</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Dwarka/digital-marketing-agency'>Dwarka</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Gujarat/digital-marketing-agency'>Gujarat</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Ghaziabad/digital-marketing-agency'>Ghaziabad</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Gwalior/digital-marketing-agency'>Gwalior</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Hyderabad/digital-marketing-agency'>Hyderabad</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Jaipur/digital-marketing-agency'>Jaipur</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Karnataka/digital-marketing-agency'>Karnataka</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Kolkata/digital-marketing-agency'>Kolkata</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Maharashtra/digital-marketing-agency'>Maharashtra</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Nashik/digital-marketing-agency'>Nashik</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Navi mumbai/digital-marketing-agency'>Navi mumbai</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Pune/digital-marketing-agency'>Pune</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Surat/digital-marketing-agency'>Surat</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/Udaipur/digital-marketing-agency'>Udaipur</Link></li>
                                                <li className='px-2 mb-2'><Link to='/digidzn-react/varanasi/digital-marketing-agency'>varanasi</Link></li>
                                            </Col>
                                        </Row>
                                    </ul>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                    <div id="map-container">
                        <img src={clientMap} className='w-100' />
                    </div>
                </div>
            </div>

            <div className="section2">
                <Link to='./news'>
                    <Container>
                        <Row >
                            <Col md={1}></Col>
                            <Col md={5} className="mt-5 sec-2-heading">
                                <h1 className='mb-0'>It Feels good</h1><br />
                                <h1><mark className="mark-words">To be talked about</mark></h1>
                            </Col>

                            <Col md={6} className="mt-5 sec-2-paragraph mb-2">
                                <Row>
                                    <Carousel
                                        swipeable={false}
                                        draggable={false}
                                        responsive={responsive}
                                        infinite={true}
                                        autoPlay={true}
                                        customTransition="transform 2000ms ease-in-out"
                                        transitionDuration={2000}
                                        containerClass="carousel-container"
                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                        partialVisible={false}
                                        arrows={false}
                                        autoPlaySpeed={2000}
                                        showDots={false}
                                        focusOnSelect={false}
                                        itemClass="mx-2"
                                    >
                                        <div>
                                            <img
                                                className="d-block img-icon"
                                                src={indian}
                                                alt="indian"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                className="d-block img-icon"
                                                src={cxo}
                                                alt="cxo"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                className="d-block img-icon"
                                                src={inidatv}
                                                alt="inidatv"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                className="d-block img-icon"
                                                src={times}
                                                alt="times"
                                            />
                                        </div>
                                    </Carousel>

                                </Row>

                                {/* Columns are always 50% wide, on mobile and desktop */}
                            </Col>
                            <Col xs={1}></Col>
                            <Col xs={11} className="mb-3">
                                <p className='text-dark'>
                                    A good offline-online synergy approach
                                    ensures that the customer gets maximum
                                    RoI of his rupee spent. From online marketing
                                    to holding offline events to making a presence
                                    in the important events, digDZN has been there everywhere.
                                </p>
                            </Col>


                        </Row>
                    </Container>
                </Link>
            </div>

            <TestimonialNew />

            {/* section4-client testimonial */}


            {/* section4 full growth */}

            <div>
                <div className='homepage-container1 py-3 py-md-5'>
                    <Container className='py-5 px-5 px-md-2'>
                        <Row>
                            <Col md={12} className="mb-5">
                                <h3 className='f-57 text-white mb-2 mb-md-5 text-center'>Full Growth Capabilities</h3>
                            </Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to='./seo' className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Search Engine Optimisation – SEO</h3>
                                            <p className='banner-desc'>Get more organic traffic to your website.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./campaigns" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Pay Per Click – PPC</h3>
                                            <p className='banner-desc'>Produce more scales rather than clicks</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>


                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="#" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Website Design & Development</h3>
                                            <p className='banner-desc'>Design, build and grow.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./social-media" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Social Media Marketing – SMM</h3>
                                            <p className='banner-desc'>Echoing your brand loudly in the virtual marketing ecosystem</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>

                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Content Marketing</h3>
                                            <p className='banner-desc'>Define your company and tell the world who you are.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5p-0">
                                <div className='full-section'>
                                    <Link to="./ecommerce" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">eCommerce Marketing</h3>
                                            <p className='banner-desc'>Innovate, evolve and thrive your commerce.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <Container>
                    <Row>
                        <Col className="text-center mt-5 heading-full-growth">
                            <h1>
                                Learning Bytes
                            </h1>
                        </Col>
                    </Row>
                </Container>
                <Container>

                    <Row className="mt-5">
                        {
                            bloglist.sort((a, b) => new Date(b.updated_datetime) - new Date(a.updated_datetime))
                                .slice(0, 3)
                                .map((blog) => {
                                    const regex = /(<([^>]+)>)/ig;
                                    let [year, month, dates] = blog.start_datetime.split("-");
                                    let months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
                                    let values = `${months[parseInt(month) - 1]} ${dates.split(" ")[0]} ${year}`;
                                    console.log(blog.seo_url);
                                    return (
                                        <Col md={4} xs={12} >
                                            <Card className="text-center mt-3 rounded card-blog">
                                                <span className='catgory-blog'>{blog.blogCategories[0].category_name}</span>
                                                {
                                                    blog.blog_image_url != null ? (
                                                        <Card.Img variant="top"
                                                            className="blog-img border img-fluid"
                                                            src={`${BASE_IMAGE_URL}${blog.blog_image_url}`} />
                                                    ) : (
                                                        <div className='no-img-div'>
                                                            No image
                                                        </div>
                                                    )
                                                }

                                                <Card.Body>
                                                    <Card.Img
                                                        className='rounded-circle wid-blog'
                                                        src={`${MASTER_IMAGE_URL}${blog.master_profile}`}
                                                        alt='user' />
                                                    <Card.Title className='blog-title mt-4'>{blog.blog_name}</Card.Title>
                                                    <Card.Text className="mt-4">
                                                        <div>{ReactHtmlParser(blog?.description)}</div>
                                                    </Card.Text>
                                                    <Link className="read-more-txt" to={`/blog-view/${blog.seo_url}`} >Read More</Link>
                                                </Card.Body>
                                                <Card.Footer className="text-muted">
                                                    <span className="text-muted footer-list-blog text-capitalize">{values}</span>
                                                    <span>•</span>

                                                    <span className="text-muted footer-list-blog ">Comments : {blog.blogCommentsCount}</span>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    )

                                })

                        }
                        {/* <div className='pagination-div'>
                        <button className='preview-btn' disabled={blogPagination.page <= 1} type='button' onClick={handlePrevious}> {"<< Previous"}</button>
                        <button className='next-btn' disabled={disableNextBtn == false} type='button' onClick={handleNext}>{"Next >>"}</button>
                    </div> */}
                        <Col xs={12} className='text-center py-5' >
                            <Link to='./blog' className="read-more-txt">View More</Link>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col md={4} className="mb-3">
                            <Card className="border-card h-100" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" className="blog-img img-fluid" src={Blogimg1} />
                                    <Card.Title className="mt-2 blog-title">HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 24, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        We’re presuming you already know that videos are the way
                                        to go and that you’ll need to run Facebook advertising. And this implies that you’re
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Card className="border-card h-100" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" className="blog-img img-fluid" src={Blogimg2} />
                                    <Card.Title className="mt-2 blog-title">Are Facebook ads worth it? (Yes, here’s why)</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 20, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Facebook seems to have run its course. With people moving towards
                                        newer social media platforms and with all the false media, it may look like
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Card className="border-card h-100" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" className="blog-img img-fluid " src={Blogimg3} />
                                    <Card.Title className="mt-2 blog-title">Best Apps for Social Media Marketing 2021</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        August 28, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Social media is necessary for both marketers and consumers,
                                        whether you like it or not. People quickly realized how crucial it was to maintain a
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}
                </Container>
            </div>
            {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}

            <div className="ready-kick-bg mt-3 yellow-bg">

                <Container >
                    <Row>
                        <Col className="text-center sec-6-head mt-4">
                            <h1 className='h4'>Ready To Kick Off Your<br></br> Growth Journey? 🚀</h1>
                            <Link to="./contact-us"> <button className="mt-2 button-custom">SPEAK TO DIGIDZN</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='my-5 addtional'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Accordion defaultActiveKey="">
                                {content ? <Accordion.Item eventKey="2" className='mb-4'>
                                    <Accordion.Header>Additional Information</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Here at DigiDZN, a top digital marketing agency in <strong>{content}</strong>, we strongly believe in the power of digital transformation and the importance of staying ahead of the ever-changing trends. Our main objective is to help businesses grow and improve their brand digitally by implementing innovative strategies. Our team consists of highly skilled and passionate marketers who work tirelessly to ensure we select the most suitable platforms for your brand, thus reducing costs and increasing visibility. </p>
                                        <p>To ensure that your brand achieves maximum returns, we are the best digital marketing agency in <strong>{content}</strong>, carefully crafting unique strategies considering your brand, industry, and target market. It's essential to understand that even though your brand may operate within a specific industry, it's still unique and cannot be compared to others. Blindly following trends or copying competitors may not always yield the desired results. Therefore, as a digital marketing company, we develop tailor-made strategies specific to your brand, ensuring that your target market identifies with your brand and that your returns are maximized while minimizing your expenses.</p>
                                        <p>Crafting a distinctive brand image in today's competitive market is daunting. Each brand has its unique digital strategy, and identifying the perfect combination for your brand's success is crucial. It's unrealistic to assume that launching a brand will automatically attract customers; people need to be persuaded that your product or service surpasses that of your competitors, and that is where we come to assist you in achieving your goal as the top digital marketing agency in <strong>{content}</strong>, </p>
                                        <p>At DigiDZN, we strive to devise a tailored marketing mix for your brand to guarantee maximum exposure and engagement with your target audience, wherever they may be. Our team of seasoned professionals collaborates with you to grasp your product, your brand, and your industry, allowing us to devise customized strategies that boost your brand's presence and visibility, ensuring that we reach a vast target audience and deliver optimal results with the help of our digital marketing services in <strong>{content}</strong>.</p>
                                        <p>digiDZN is a digital marketing agency committed to adding value to your brand. Our unique approach sets us apart from other digital marketing agencies in <strong>{content}</strong> as we allow our results to speak for us. Our primary focus is on maximizing returns while minimizing expenses. We utilize a highly customized method that evolves and adapts to suit your brand's needs. Our digital marketing service in <strong>{content}</strong> includes the following:</p>
                                        <p><strong>Monitoring:</strong> It's crucial to keep a watchful eye on your competitors and industry trends, as well as your potential customers' interactions with your platform. Every visit to your website or app is meticulously tracked to analyze consumer behaviour and comprehend what they like and dislike about your platform. We further analyze sales trends to guide you in selecting the best product mix to offer and monitor consumer behaviour around your brand by identifying the most popular content consumed by your audience.</p>
                                        <p><strong>Media Mix:</strong> Selecting the appropriate mediums for your brand is critical. As a top digital marketing agency in <strong>{content}</strong>, we keep track of industry trends, media trends, and your brand's requirements to create a digital media mix that impresses your audience and reaches the widest possible target market, regardless of their location.</p>
                                        <p><strong>Strategy Mix:</strong> Our digital marketing strategies are based on an initial brand analysis and competitor research to determine the best digital platforms and customize our approach to maximize potential. We aim to showcase the right product to the right audience at the right place and time, minimizing expenses and maximizing returns through digital marketing. We allow our clients to speak for us rather than claiming to increase returns like other digital marketing agencies.</p>
                                        <p><strong>Campaign Development:</strong> Once the strategy, media, and marketing mix have been finalized, we begin developing campaigns for your brand. These campaigns are monitored around the clock to assess their performance, including the performance of the creatives, ads, and other factors. We, the best digital marketing company in <strong>{content}</strong>, capture and analyze every data point to ensure that we comprehensively understand your customers' insights and can develop and modify strategies based on our findings.</p>
                                        <p><strong>Reporting:</strong> Optimizing campaigns is an ongoing process. To ensure that we maximize results from all advertising platforms, we generate regular reports to analyze performance and identify areas for improvement in our digital marketing activities. Each report covers performance, areas for improvement, and performance increases over time resulting from our efforts. DigiDZN is among the top digital marketing agencies in India, delivering the best possible outcomes for our clients.</p>
                                        <p>If you are searching for the best digital marketing in <strong>{content}</strong>, then DigiDZN is the answer to all your digital marketing problems. Contact us at the earliest so that you can achieve your goals to the earliest.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                    :

                                    <Accordion.Item eventKey="0" className='mb-4'>
                                        <Accordion.Header>Additional Information</Accordion.Header>
                                        <Accordion.Body>
                                            <p>digiDZN is the Digital Marketing Agency near you that you can trust to take your business to the next level. As the Best Digital Marketing Agency in India, we pride ourselves on offering personalized and innovative solutions that cater to your unique needs. Whether you're looking for a Digital Marketing Company or a Marketing Agency, we've got you covered.</p>
                                            <p>Our highly dedicated team of digital marketers utilizes their vast knowledge and experience to help your business achieve its desired growth and stand out in the crowd. As the Best Digital Marketing Company, we promise to help you stay ahead of the competition with our goal-oriented approach.</p>
                                            <p>Our expert strategies optimize conversions and increase search visibility to generate genuine leads, enabling you to reach your goals. As a leading Digital Marketing Agency, we understand the importance of staying updated with the latest trends and technology in digital marketing, which is why our team keeps learning and evolving to create the perfect strategies for your business. Our commitment to delivering the best results has helped us make a real difference for our clients in the short and long term. By partnering with us, you can expect a comprehensive and tailored approach to digital marketing backed by years of experience and a commitment to excellence.</p>
                                            <p>If you're looking for a Marketing Agency Near Me or a Digital Marketing Services provider, look no further than digiDZN. We're delighted to build enduring relationships with our clients, both locally and globally, and take pride in being the fastest-growing Digital Marketing Agency in Delhi. Our omnichannel approach builds sustainable solutions that help you consistently achieve your business objectives. Contact us today to learn more about our customized and innovative Digital Marketing Services and take the first step toward achieving your goals.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>}

                                {/* <Accordion.Item eventKey="1">
                                <Accordion.Header>Faq</Accordion.Header>
                                <Accordion.Body>
                                <div className='faq'>
                                    <div className='my-4'>
                                        <h6 className='mb-2'><strong>1.Bootstrap heading</strong></h6>
                                        <p>A well-known quote, contained in a blockquote element.</p>
                                    </div>
                                    <div className='my-4'>
                                        <h6 className='mb-2'><strong>1.Bootstrap heading</strong></h6>
                                        <p>A well-known quote, contained in a blockquote element.</p>
                                    </div>
                                </div>
                                </Accordion.Body>
                            </Accordion.Item> */}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />

        </div>
    )
}


export default Home
