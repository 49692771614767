import React from 'react'
import { useState, useEffect  } from 'react';
import emailjs from 'emailjs-com';
import { Link, Redirect } from 'react-router-dom'
const Forbusform = () => {

    const initialValues = {name:null, email:null,phonenumber:null,message:null,recaptcha:null}; 
     const [formValues, setFormValues] = useState(initialValues);
     const [formErrors, setFormErrors] = useState({});
     const [isSubmit, setIsSubmit] = useState(false);
     const [isVerified, setVerified] = useState(null);  
     const handleChange = (e) => {
         
         const {name, value } = e.target;
         setFormValues({ ...formValues, [name]:value});
         
     };
         
        // if (this.state.verified) {
        //     fetch()
        //     .then(this.handleSuccess)
        //     .catch(error => alert(error))
        //     .preventDefault()
        // }
        // else {this.setState({reply: "Please verify the ReCaptcha."})}
        React.useEffect(() => {
            // register({ name: "spam" }, { required: "prove you are no bot plz!" });
            }, []);

            const handleRecaptcha = value => {
                console.log("Captcha value:", value);
                setVerified(value);
                };
    
     const handleSubmit = (e) =>{
         e.preventDefault();
         setFormErrors(validate(formValues)); 
         setIsSubmit(true);
        
         
        //  emailjs.sendForm('service_ymarvgj', 'template_1g1fdio', e.target, 'user_NiL8Bf14JPlgveD96mnuV')
        //  .then((result) => {
        //      console.log(result.text);
        //  }, (error) => {
        //      console.log(error.text);
        //  });
     };
     useEffect(() =>{
        if(Object.keys(formErrors).length === 0 && isSubmit)
        {
            
            console.log(formValues);
        }
     },[formErrors])

     const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /^[6-9]\d{9}$/;
        
        if(!values.name){
            errors.name = "please enter your name";
        }
        if(!values.email){
            errors.email = "please enter your email address";
        } else if(!regex.test(values.email)){
            errors.email = "this is not a valid email format";
        }

        if(!values.phonenumber){
            errors.phonenumber = "please enter your phone number";
        } 
        else if(!phone.test(values.phonenumber)){
            errors.phonenumber = "please enter your valid phone number";
        }
        if(!values.message){
            errors.message = "please enter your message";
        }
        // if(values.recaptcha == ""){
        //     errors.recaptcha = "The Captcha field cannot be blank. Please enter a value";
        // }
        
       return errors; 
     };
    return (
        
        <div>
            <form className="" onSubmit={handleSubmit}>
                                <input type='text'

                                    name="name"
                                    className='business-field1'
                                       value={formValues.name}
                                       onChange={handleChange}
                                    placeholder="Name"
                                />
                                <p className="mb-1">{formErrors.name}</p>
                                <input type="email"
                                        className='business-field1'
                                    name="email"
                                       value={formValues.email}
                                       onChange={handleChange}
                                    placeholder="email"
                                />
                                <p className="mb-1">{formErrors.email}</p>
                                <input type="tel"
                                       onKeyPress="if(this.value.length==10) return false;"
                                       name="phonenumber" 
                                       value={formValues.phonenumber}
                                       onChange={handleChange}
                                    placeholder="phone number"
                                    className='business-field1'
                                />
                                <p className="mb-1">{formErrors.phonenumber}</p>
                                <textarea
                                   
                                    placeholder="message"
                                    name="message"
                                    className='business-field1 mb-1'
                                   value={formValues.message}
                                   onChange={handleChange}
                                >

                                </textarea>
                                <p className="mb-1">{formErrors.message}</p>



                                {/* <ReCAPTCHA
                                    className="g-recaptcha"
                                    sitekey="6LdqGGQaAAAAABS5Vtjm1NTJ9HdMK8rKjR96V0gE"
                                    onChange={this.onChange}
                                    theme="dark"
                                />
                                { this.state.verified ? <p id="error" className="error">{this.state.reply}</p> : null }
                                { this.state.showSuccess ? <p id="success" className="success">Thank you, we will be in touch asap.</p> : null } */}
                                {/* <p className="mb-4">{formErrors.recaptcha}</p>    */}
                                <button className="bussiness-btn ">send</button>
                                {Object.keys(formErrors).length === 0 && isSubmit ? (<Redirect to='/thank-you-page'  />): (<div></div>) }
                            </form>
        </div>
    )
}

export default Forbusform
