import React from "react";
import { Row, Col, Container, Figure, Button, Modal } from 'react-bootstrap';
import cxo from '../asset/img/CXO.png'
import inidatv from '../asset/img/Ndtv 2.png'
import times from '../asset/img/the.png'
import indian from '../asset/img/indian retailer.png'
import midDay from '../asset/img/mid-day.png'
import bwLogo from '../asset/img/BW-logo.png'
import pcLogo from '../asset/img/PC-logo.webp'
import ecLogo from '../asset/img/expresscomputer.webp'
import MediaBulletins from '../asset/img/Media-Bulletins.png'
import bnwLogo from '../asset/img/business-news-week.png'
import sbnLogo from '../asset/img/smartbusinessnews.png'
import main from '../asset/img/main_logo.png'
import media from '../asset/img/media.png'

const News = () => {
    return (
        <div>
            <section id="results-driven">
                <Container>
                    <Row>
                        <h1>digiDZN <span>in News</span></h1>
                        <h3>#1 digital marketing agency</h3>
                    </Row>
                </Container>
            </section>

            <div className="news-details mt-4">
                <Container>
                    <Row>
                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={inidatv} alt="inidatv" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    5 trends Indian Ecommerce industry can expect to see in 2023-24 and how to prepare
                                </h6>
                                <p className="mt-1">
                                    To thrive in India's rapidly expanding e-commerce industry, businesses must prioritize investments in mobile optimization, social media marketing, data analytics, sustainability, and omnichannel retail strategies. By doing so, they can prepare for emerging trends and stay ahead of the competition in a market projected to reach $110 billion by 2025
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.indiatvnews.com/business/news/indian-ecommerce-five-trends-industry-can-expect-to-see-in-2023-24-and-how-to-prepare-2023-03-16-855116"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={times} alt="times" className="w-75 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    How women are changing the dynamics of Indian Industry
                                </h6>
                                <p className="mt-1">
                                    Indian business is transforming substantially as more women enter the workforce. It covers the advantages of having women in the workplace and emphasizes the significance of developing soft skills and meeting their specific psychological and physiological requirements. The author encourages businesses to reflect on their efforts to assist this transition and pushes for establishing a gender-neutral workplace that encourages diversity and inclusivity.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://timesofindia.indiatimes.com/blogs/voices/how-women-are-changing-the-dynamics-of-indian-industry/"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>

                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={midDay} alt="Mid Day" className="w-75 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    From Digital Marketing Enthusiast to Successful Entrepreneur: The Story of Sangeeta and digiDZN
                                </h6>
                                <p className="mt-1">
                                    It all started when Sangeeta took courses in digital marketing to help her husband*s fintech startup. Through this experience, she discovered that the digital marketing segment they belonged to was underserved. Driven by this realization, Sangeeta launched her own digital marketing agency, DigiDZN, in April 2016.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.mid-day.com/amp/brand-media/article/from-digital-marketing-enthusiast-to-successful-entrepreneur-the-story-23283434"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={indian} alt="indian" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    How the E-Commerce Industry is Revamping in This Digital Era
                                </h6>
                                <p className="mt-1">
                                    The e-commerce industry is rapidly growing, and success demands understanding dynamic variables such as categories, demographics, and tech anxiety. Creating a niche with quality products, competitive prices, and engaging customer experiences is crucial. To compete in this fast-paced market, businesses must stay up-to-date with consumer behaviour and trends
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.indianretailer.com/article/retail-business/retail-trends/how-e-commerce-industry-revamping-digital-era"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={bwLogo} alt="businessworld" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    Sangeeta Verma, founder digiDZN & digiCART India
                                </h6>
                                <p className="mt-1">
                                    Sangeeta Verma, founder digiDZN & digiCART India which is a Digital Marketing & ecommerce company says that " Lack of reliable Digital Marketing options for SMEs made Sangeeta Verma launch a Digital Marketing Agency, digiDZN, focusing on small & medium business enterprises. With a natural knack of leading teams & spotting the trends, very soon she also moved into ecommerce space.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.businessworld.in/article/Game-changing-Companies-And-Pioneering-Entrepreneurs-To-Watch-Out-In-2023/02-05-2023-475080/"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={pcLogo} alt="pc quest" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    Harnessing the Power of Technology to Maximize Your Business Success
                                </h6>
                                <p className="mt-1">
                                    Business, is a step wise approach wherein you market to get leads, work on the leads to convert them as customers, manufacture products/provide services to them, collect payments, & repeat. The process is simple. However, given the management sizes (mostly family-owned businesses) of most businesses in India, the business owners struggle to balance these simple steps.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.pcquest.com/harnessing-the-power-of-technology-to-maximize-your-business-success/"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={cxo} alt="cxo" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    Breaking Barriers and Achieving Success in Digital Era
                                </h6>
                                <p className="mt-1">
                                    Success in the digital age demands a dynamic approach that incorporates adaptability, flexibility, and staying up-to-date with new tech. Overcoming barriers requires leveraging trusted resources, embracing experimentation, and responding promptly to customer needs. Small and mid-sized businesses must adapt to changing markets to maintain a competitive edge. Achieving success requires commitment, a proactive mindset, and seizing opportunities.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.cxotoday.com/specials/breaking-barriers-and-achieving-success-in-digital-era/"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={ecLogo} alt="expresscomputer" className="w-50 mb-4 bg-dark"></img>
                                </div>
                                <h6 className="text-center">
                                    Embrace technology as a tool to revolutionize industries and businesses
                                </h6>
                                <p className="mt-1">
                                    We are living in an extraordinary time of transition. Technology is rapidly revolutionizing the way we live and work, ushering in a new era of increased productivity and efficiency. From the advent of the internet to the rise of artificial intelligence, the potential for technological advancement is seemingly limitless.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.expresscomputer.in/guest-blogs/embrace-technology-as-a-tool-to-revolutionize-industries-and-businesses/97323/"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={cxo} alt="cxo" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    Bridging the Gap and Empowering Business Growth through Digital Marketing
                                </h6>
                                <p className="mt-1">
                                    My entrepreneurial journey has been very exciting. Our digital marketing business came into being seven years ago in April 2016. With the support of 2 staff working from home, lots of passion & and with a small team comprising two dedicated members.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.cxotoday.com/interviews/digidzn-bridging-the-gap-and-empowering-business-growth-through-digital-marketing/"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>


                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={cxo} alt="cxo" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    digiDZN introduces eComically.com: Simplifying Tax and Payment Reports for E-Commerce Sellers
                                </h6>
                                <p className="mt-1">
                                    In an ever-evolving world of e-commerce, staying on top of marketplaces’ tax and payment reports can be a daunting task for sellers. digiDZN thinks that all sellers should be allowed to use and benefit from e-commerce. They intend to equip online business owners with the tools they need to easily handle the intricacies of tax and payment reporting with the launch of eComically.com.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.digidzn.com/blog-view/digidzn-introduces-ecomically-com-simplifying-tax-and-payment-reports-for-e-commerce-sellers"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>

                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={main} alt="cxo" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    Embracing AI and Automation in agencies
                                </h6>
                                <p className="mt-1">
                                    In today’s swiftly evolving digital environment, the adoption of artificial intelligence (AI) and automation has become not only an option but a necessity for any company looking to survive, compete, and offer exclusive results to its clients.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://www.adgully.com/embracing-ai-and-automation-in-agencies-sangeeta-verma-136086.html"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>

                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={times} alt="times" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    Unlocking the potential of technology to enhance your credibility at the workplace
                                </h6>
                                <p className="mt-1">
                                    Technology is rapidly reshaping the corporate world, putting every business under pressure to adapt or at risk of falling behind. With technological advancements, businesses can become more employee-centric, personalizing the worker experience, unleashing the full power of their employees, and simplifying the process of how they operate. In fact, embracing technology would help you adapt and stay ahead of the curve.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://timesofindia.indiatimes.com/blogs/voices/unlocking-the-potential-of-technology-to-enhance-your-credibility-at-the-workplace/"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>

                        <Col xs={12} md={4} className="mb-0">
                            <div className="full-growthcontent mt-3">
                                <div className="text-center">
                                    <img src={media} alt="media infoline" className="w-50 mb-4"></img>
                                </div>
                                <h6 className="text-center">
                                    DigiDZN survey claims 75% of Indian marketers witnessed increase in market size
                                </h6>
                                <p className="mt-1">
                                    A recent survey conducted by digiDZN, a digital marketing platform revealed the tremendous impact of digital marketing in generating growth for Indian companies, especially during the revolutionary period caused by the worldwide pandemic. According to the poll, 75% of Indian marketers based out of metro cities have seen a major increase in their company’s market size as a result of employing digital marketing to promote their products. The survey, which polled over 7000 Indian marketers, found 90% of marketers believe that digital marketing is critical to their success.
                                </p>
                                <p className="m-0"><stong><b>by Sangeeta verma,</b> founder digiDZN</stong></p>
                                <hr></hr>

                                <a className="text-center d-block mb-4" target="blank" rel="noopener" href="https://digidzn.com/blog-view/digidzn-survey-claims-75-of-indian-marketers-witnessed-increase-in-market-size"> <button className="text-uppercase mt-2 about-sec2-btn">read full article </button></a>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

        </div>
    )
}

export default News;