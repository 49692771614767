import React from 'react'
import Client3 from '../asset/img/client3.png'
import Client4 from '../asset/img/client4.png'
import Client5 from '../asset/img/client5.png'
import Client6 from '../asset/img/client6.png'
import Client1 from '../asset/img/Amardeep.webp'
import Client2 from '../asset/img/client2.webp'
import Icon32 from '../asset/img/logo32.jpg'
import './testimonial.css'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Row, Col, Container } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const PreviousBtn = (props) => {
    console.log(props);
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FaAngleLeft style={{ color: "white", fontSize: "23px" }} />
        </div>
    );
};
const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FaAngleRight style={{ color: "white", fontSize: "23px" }} />
        </div>
    );
};



function testimonial() {
    const client = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,

    };

    return (
        <div>

            <div className=" homepage-container4">
                <Container>
                    <Row>
                        <Col md={2}>
                        </Col>
                        <Col md={8} >
                            <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} {...client}>
                                <div className="p-4">
                                    <Row>
                                        <Col md={5} lg={3}>
                                            <img
                                                className="d-block testimonial-img center"
                                                width="125"
                                                src={Client1}
                                                alt="Client1"
                                            />
                                        </Col>
                                        <Col md={7} lg={9} className="testimonial-slider">
                                            <p>
                                                Having a trusted partner like digiDZN has actually
                                                helped me to grow my business at a pace! The team helped
                                                me to reach the audience with speedy growth tactics and
                                                creative campaign ideas.
                                            </p>
                                            <h5 className="mt-4" style={{ color: 'white!important' }}>
                                                <em style={{ color: 'white!important' }}>Amardeep Gulri</em>
                                            </h5>
                                            <h6 style={{ color: 'white!important' }}><em style={{ color: 'white!important' }}>Deco Arte</em></h6>

                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-4">
                                    <Row>
                                        <Col md={5} lg={3}>
                                            <img
                                                className="d-block testimonial-img center"
                                                width="125"
                                                src={Client2}
                                                alt="Client1"
                                            />
                                        </Col>
                                        <Col md={7} lg={9} className="testimonial-slider">
                                            <p>
                                                The digiDZN team is expert in strategizing
                                                and delivering results. Partnering them allowed
                                                us to achieve social media prominence on all platforms,
                                                along with the accomplishment of key metrics and growth objectives.
                                            </p>
                                            <h5 className="mt-4" style={{ color: 'white!important' }}>
                                                <em style={{ color: 'white!important' }}>Anish Bansal</em>
                                            </h5>
                                            <h6 style={{ color: 'white!important' }}><em style={{ color: 'white!important' }}>Maven</em></h6>

                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-4">
                                    <Row>
                                        <Col md={5} lg={3}>
                                            <img
                                                className="d-block testimonial-img center"
                                                width="125"
                                                src={Client6}
                                                alt="Client1"
                                            />
                                        </Col>
                                        <Col md={7} lg={9} className="testimonial-slider">
                                            <p>
                                                We are thankful to the entire team of digiDZN for putting their
                                                great efforts! They are tech-savvy & proficient in delivering
                                                consistent results. Undeniably, they are ahead of the curve
                                                in understanding the requirements & then fulfilling them
                                                continuously!

                                            </p>
                                            <h5 className="mt-4">
                                                <em>Gurpreet</em>
                                            </h5>
                                            <h6><em>Gracini Studios</em></h6>

                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-4">
                                    <Row>
                                        <Col md={5} lg={3}>
                                            <img
                                                className="d-block testimonial-img center"
                                                width="140"
                                                src={Client3}
                                                alt="Client1"
                                            />
                                        </Col>
                                        <Col md={7} lg={9} className="testimonial-slider">
                                            <p>
                                                The digiDZN squad has contributed a lot in our growth.
                                                Within 6 months of working with the team, we grew our
                                                online presence among the audience along with the increase
                                                in online orders!

                                            </p>
                                            <h5 className="mt-4">
                                                <em>Shikha Bhutani</em>
                                            </h5>
                                            <h6><em>Lavanika</em></h6>

                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-4">
                                    <Row>
                                        <Col md={5} lg={3}>
                                            <img
                                                className="d-block testimonial-img center"
                                                width="140"
                                                src={Client4}
                                                alt="Client1"
                                            />
                                        </Col>
                                        <Col md={7} lg={9} className="testimonial-slider">
                                            <p>
                                                They helped us to launch our online business from
                                                website development to social media presence.
                                                Loved working with the digiDZN team and will
                                                recommend them to anyone for sure! Thank you,
                                                team, for your great efforts on our project!
                                            </p>
                                            <h5 className="mt-4">
                                                <em>Sandhya Maheshwari</em>
                                            </h5>
                                            <h6><em>Surface Concept</em></h6>

                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-4">
                                    <Row>
                                        <Col md={5} lg={3}>
                                            <img
                                                className="d-block testimonial-img center"
                                                width="140"
                                                src={Client5}
                                                alt="Client1"
                                            />
                                        </Col>
                                        <Col md={7} lg={9} className="testimonial-slider">
                                            <p>
                                                They are creative and experienced professionals to deliver great
                                                results and growth. With their methodological SEO approach and
                                                digital marketing strategy helped us to become favourite among our
                                                customers! We are excited to continue our relationship with them.

                                            </p>
                                            <h5 className="mt-4">
                                                <em>Amardeep Gulri</em>
                                            </h5>
                                            <h6><em>Deco Arte</em></h6>

                                        </Col>
                                    </Row>
                                </div>
                            </Slider>
                        </Col>
                        <Col md={2}>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default testimonial
