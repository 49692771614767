import React from 'react'
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import { Row, Col, Container, Card } from 'react-bootstrap';
import Social2 from '../asset/img/social-media-2.png'
import Social3 from '../asset/img/social-media-3.png'
import Social1 from '../asset/img/social-media-1.png'
import Result1 from '../asset/img/sec-10.jpg'
import Result2 from '../asset/img/sec-11.jpg'
import Blogimg1 from '../asset/img/blog-img1.png'
import Blogimg2 from '../asset/img/blog-img2.jpg'
import Blogimg3 from '../asset/img/blog-img3.png'
import Footer from '../Components/Footer';
import Testimonial from './testimonial';
import { Helmet } from 'react-helmet-async';
import TestimonialNew from './testimonial_new';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import Carousel1 from '../Components/carousel1';
import { postRequest, MD5Hash, BLOG_LIST, BASE_IMAGE_URL, MASTER_IMAGE_URL } from '../util/api';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const Socialmedia = () => {

    const [bloglist, setBlogList] = useState([]);
    const [disableNextBtn, setDisableNextBtn] = useState(true);
    const [blogPagination, setBlogPagination] = useState({
        page: 1
    });

    useEffect(() => {
        blogList();
    }, [])
    const blogList = async () => {
    
        let auth_token = MD5Hash('');
        let data = {
            "auth_token": auth_token,
            "page": blogPagination.page
        }
        var res = await postRequest(BLOG_LIST, data);
        // debugger;
        if (res.success) {
            setBlogList(res?.parameters?.data);
            setDisableNextBtn(true)
        } else {
            setDisableNextBtn(false)
        }
    }


    return (
        <div>
            <Helmet>
                <title>Social Media Marketing Agency in Delhi NCR | digiDZN</title>
                <meta name="description" content="Need a social media agency in Delhi to help communicate with customers. Grow your online presence with a 360-degree solution for your brand." />
            </Helmet>
            <div className="social-banner">
                <Container>
                    <Row>
                        <Col className="social-heading mt-5">
                            <h2>
                                Social Media with befitting strategy and approach
                            </h2>
                            <h6 className="mt-4">For an edge to edge Social Media Advertising,<br />
                                we leverage our skills to reach the right audience and initiate a conversation with them!</h6>

                        </Col>

                    </Row>
                </Container>
            </div>
            {/* section 2 social media */}
            <div className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xs={12} >
                            <img
                                className="d-block social-media "
                                width="125"
                                src={Social2}
                                alt="Social2"
                            />
                        </Col>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                                Branding
                            </h1>
                            <p>

                                For brand awareness and visibility among the targeted audience,
                                branding is essential. Let's build a unique identity of your brand
                                to give an edge to edge competition in the market. A brand is all
                                about bridging the gap between the market and the ability of your
                                dreams to push harder. A compelling approach is what requires to
                                build a brand that lives up to the expectations of customers and
                                the company's own ethics.

                            </p>
                            <Link to="./contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section2 */}
            <div className="mt-10 desktop-tracking">
                <Container>
                    <Row>
                        <Col md={6} xs={12} className="social-section ">
                            <h1>
                                Organic Advertising
                            </h1>
                            <p>

                                Organic social media advertising is vital to build and grow brand.
                                To maintain a brand presence, organic social media advertising is
                                necessary that's usually driven with creative content strategy.
                                Moreover, this content strategy engages the audience on the platform
                                and develop loyalty with them! For effective organic social media advertising,
                                maximize on building the brand with your compelling products and services,
                                rather than just doing promotions.

                            </p>
                            <Link to="./contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                        <Col md={6} xs={12}>
                            <img
                                className="d-block social-media "
                                width="125"
                                src={Social3}
                                alt="Social3"
                            />

                        </Col>
                    </Row>
                </Container>
            </div>

            {/* mobile responsive */}

            <div className="mt-10 mob-res-tracking">
                <Container>
                    <Row>
                        <Col md={6} xs={12} className="social-section">

                            <img
                                className="d-block social-media "
                                width="125"
                                src={Social3}
                                alt="Social3"
                            />

                        </Col>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                                Tracking
                            </h1>
                            <p>

                                Tracking campaigns is essential for every business as it drives
                                leads and conversions. Understanding the customer's needs, purchase behaviour,
                                and decision-making process are critical to track the campaigns
                                if they are running well or not. From campaign performance to understanding
                                the strategy, tracking can actually bolster the social media campaigns.

                            </p>
                            <Link to="./contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section3 */}
            <div className="mt-5 mb-5">
                <Container>
                    <Row>
                        <Col md={6} xs={12}>
                            <img
                                className="d-block social-media "
                                width="125"
                                src={Social1}
                                alt="Social1"
                            />
                        </Col>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                                Paid Advertising
                            </h1>
                            <p>

                                Do you know that 86% of marketers use a combination of both,
                                Organic and Paid Advertising to build a brand? For quality
                                leads and conversions, paid marketing on different social media
                                platforms is crucial. It is beneficial in targeting the right
                                audience as per the industry, age, interest and other factors
                                along with the distribution of content. Through paid advertising,
                                drive more traffic, traction and conversions keeping in mind
                                the behaviour and expectation of customers!

                            </p>
                            <Link to="./contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* testimonial           */}
            {/* <Testimonial /> */}
            <TestimonialNew />
            {/* section4 */}

            {/* <div className="bg-result mt-1">
            <Container>
                <Row>
                    <Col className="text-center mt-1 heading-full-growth">
                    <h1>
                    Consistently Delivering Results
                    </h1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className="mt-5 ">
                        <div className="bgcolor-result"> 
                                    <img
                                    className="d-block img-result border-result"
                                    src={Result1}
                                    alt="Sec-10"
                                    />
                        </div>
                    </Col>
                    <Col className="mt-5">
                        <div className="bgcolor-result">
                                   <img
                                    className="d-block border-result img-result"
                                    src={Result2}
                                    alt="Sec-10"
                                    />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-5">
                    <Link to="./results"><button className="growth-button">Read more</button></Link>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className="text-center mt-5 heading-full-growth">
                    <h1>
                    Learning Bytes
                    </h1>
                    </Col>
                </Row>
            </Container>
        </div> */}

            <Carousel1 />



            {/* <div>
            <Container>
                <Row>
                    <Col md={4}> 
                    <Card className="border-card" style={{ width: "100%" }}>
                        <Card.Body>
                        <Card.Img variant="top" class="blog-img img-fluid" src={Blogimg1} />
                        <Card.Title className="mt-2 blog-title">HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Card.Title>
                        <Card.Subtitle className="mb-2 mt-2 blog-sub">
                        September 24, 2021
                        </Card.Subtitle>
                        <Card.Text className="mb-2 mt-2 blog-text">
                        We’re presuming you already know that videos are the way
                        to go and that you’ll need to run Facebook advertising. And this implies that you’re
                        </Card.Text>
                        <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                        </Card.Body>
                    </Card>
                    </Col>
                <Col md={4}>
                <Card className="border-card" style={{ width: "100%" }}>
                    <Card.Body>
                    <Card.Img variant="top" class="blog-img img-fluid" src={Blogimg2} />
                    <Card.Title className="mt-2 blog-title">Are Facebook ads worth it? (Yes, here’s why)</Card.Title>
                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                    September 20, 2021
                    </Card.Subtitle>
                    <Card.Text className="mb-2 mt-2 blog-text">
                    Facebook seems to have run its course. With people moving towards 
                    newer social media platforms and with all the false media, it may look like
                    </Card.Text>
                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                    </Card.Body>
                </Card>
                </Col>
                <Col md={4}>
                <Card className="border-card" style={{ width: "100%" }}>
                    <Card.Body>
                    <Card.Img variant="top" class="blog-img img-fluid " src={Blogimg3} />
                    <Card.Title className="mt-2 blog-title">Best Apps for Social Media Marketing 2021</Card.Title>
                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                    August 28, 2021
                    </Card.Subtitle>
                    <Card.Text className="mb-2 mt-2 blog-text">
                    Social media is necessary for both marketers and consumers, 
                    whether you like it or not. People quickly realized how crucial it was to maintain a
                    </Card.Text>
                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                    </Card.Body>
                </Card>
                </Col>
                </Row>
            </Container>
        </div> */}


            <div>
                <Container>

                    <Row className="mt-0">
                        <Col xs={12} className="text-center mt-0 heading-full-growth"><h1 className='mt-0'>Learning Bytes</h1></Col>
                        {
                            bloglist.sort((a, b) => new Date(b.updated_datetime) - new Date(a.updated_datetime))
                                .slice(0, 3)
                                .map((blog) => {
                                    // debugger;
                                    // console.log(blog, 'Testing....')
                                    const regex = /(<([^>]+)>)/ig;
                                    let [year, month, dates] = blog.start_datetime.split("-");
                                    let months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

                                    let values = `${months[parseInt(month) - 1]} ${dates.split(" ")[0]} ${year}`;
                                    // console.log("values :", values);
                                    console.log(blog.seo_url);
                                    return (
                                        <Col md={4} xs={12} >
                                            <Card className="text-center mt-3 rounded card-blog">
                                                <span className='catgory-blog'>{blog.blogCategories[0].category_name}</span>
                                                {
                                                    blog.blog_image_url != null ? (
                                                        <Card.Img variant="top"
                                                            className="blog-img border img-fluid"
                                                            src={`${BASE_IMAGE_URL}${blog.blog_image_url}`} />
                                                    ) : (
                                                        <div className='no-img-div'>
                                                            No image
                                                        </div>
                                                    )
                                                }

                                                <Card.Body>
                                                    <Card.Img
                                                        className='rounded-circle wid-blog'
                                                        src={`${MASTER_IMAGE_URL}${blog.master_profile}`}
                                                        alt='user' />
                                                    <Card.Title className='blog-title mt-4'>{blog.blog_name}</Card.Title>
                                                    <Card.Text className="mt-4">
                                                        {/* <div>{blog.description.replace(regex, '').slice(0,150)}</div> */}
                                                        <div>{ReactHtmlParser(blog?.description)}</div>
                                                    </Card.Text>
                                                    {/* onClick={() => navigate(blog.blog_id)} */}
                                                    <Link className="read-more-txt" to={`/blog-view/${blog.seo_url}`} >Read More</Link>
                                                </Card.Body>
                                                <Card.Footer className="text-muted">
                                                    <span className="text-muted footer-list-blog text-capitalize">{values}</span>
                                                    <span>•</span>

                                                    <span className="text-muted footer-list-blog ">Comments : {blog.blogCommentsCount}</span>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    )

                                })

                        }
                        {/* <div className='pagination-div'>
                        <button className='preview-btn' disabled={blogPagination.page <= 1} type='button' onClick={handlePrevious}> {"<< Previous"}</button>
                        <button className='next-btn' disabled={disableNextBtn == false} type='button' onClick={handleNext}>{"Next >>"}</button>
                    </div> */}
                        <Col xs={12} className='text-center py-5' >
                            <Link to='./blog' className="read-more-txt">View More</Link>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img" src={Blogimg1} />
                                    <Card.Title className="mt-2 blog-title">HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 24, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        We’re presuming you already know that videos are the way
                                        to go and that you’ll need to run Facebook advertising. And this implies that you’re
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img" src={Blogimg2} />
                                    <Card.Title className="mt-2 blog-title">Are Facebook ads worth it? (Yes, here’s why)</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 20, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Facebook seems to have run its course. With people moving towards
                                        newer social media platforms and with all the false media, it may look like
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img" src={Blogimg3} />
                                    <Card.Title className="mt-2 blog-title">Best Apps for Social Media Marketing 2021</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        August 28, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Social media is necessary for both marketers and consumers,
                                        whether you like it or not. People quickly realized how crucial it was to maintain a
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}
                </Container>
            </div>







            {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
            {/* <div class="custom-shape-divider-top-1635236144 mt-10">
            <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
            </svg>
       </div> */}
            {/* <div className="ready-kick-bg">
    
            <Container >
                <Row>
                    <Col className="text-center sec-6-head">
                    <h1>Ready To Kick Off Your Growth Journey</h1>
                    <Link to="./contact-us"> <button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                    </Col>

                </Row>
            </Container>
        </div> */}
            <div className="ready-kick-bg mt-5 yellow-bg">

                <Container >
                    <Row>
                        <Col className="text-center sec-6-head mt-4">
                            <h1 className='h4'>Ready To Kick Off Your<br></br> Growth Journey? 🚀</h1>
                            <Link to="./contact-us"> <button className="mt-2 button-custom">SPEAK TO DIGIDZN</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div>
            <Footer />

        </div>
    )
}

export default Socialmedia;
