
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Row, Col, Container, Card } from 'react-bootstrap';
import Testimonial from './testimonial';
import emailjs from 'emailjs-com';
import Bsn1 from '../asset/img/business-2.jpg'
import Footer from '../Components/Footer';
import Formbus from './Forbusform'
import { useState, useEffect  } from 'react';
const Forbusiness = () => {

    const initialValues = {name:null, email:null,phonenumber:null,message:null,recaptcha:null}; 
     const [formValues, setFormValues] = useState(initialValues);
     const [formErrors, setFormErrors] = useState({});
     const [isSubmit, setIsSubmit] = useState(false);
     const [isVerified, setVerified] = useState(null);  
     const handleChange = (e) => {
         
         const {name, value } = e.target;
         setFormValues({ ...formValues, [name]:value});
         
     };
         
        // if (this.state.verified) {
        //     fetch()
        //     .then(this.handleSuccess)
        //     .catch(error => alert(error))
        //     .preventDefault()
        // }
        // else {this.setState({reply: "Please verify the ReCaptcha."})}
        React.useEffect(() => {
            // register({ name: "spam" }, { required: "prove you are no bot plz!" });
            }, []);

            const handleRecaptcha = value => {
                console.log("Captcha value:", value);
                setVerified(value);
                };
    
     const handleSubmit = (e) =>{
         e.preventDefault();
         setFormErrors(validate(formValues)); 
         setIsSubmit(true);
        
         
        //  emailjs.sendForm('service_ymarvgj', 'template_1g1fdio', e.target, 'user_NiL8Bf14JPlgveD96mnuV')
        //  .then((result) => {
        //      console.log(result.text);
        //  }, (error) => {
        //      console.log(error.text);
        //  });
     };
     useEffect(() =>{
        if(Object.keys(formErrors).length === 0 && isSubmit)
        {
            
            console.log(formValues);
        }
     },[formErrors])

     const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /^[6-9]\d{9}$/;
        
        if(!values.name){
            errors.name = "please enter your name";
        }
        if(!values.email){
            errors.email = "please enter your email address";
        } else if(!regex.test(values.email)){
            errors.email = "this is not a valid email format";
        }

        if(!values.phonenumber){
            errors.phonenumber = "please enter your phone number";
        } 
        else if(!phone.test(values.phonenumber)){
            errors.phonenumber = "please enter your valid phone number";
        }
        if(!values.message){
            errors.message = "please enter your message";
        }
        // if(values.recaptcha == ""){
        //     errors.recaptcha = "The Captcha field cannot be blank. Please enter a value";
        // }
        
       return errors; 
     };
    return (
        <div>
            <div className='business-banner '>
                <Container >
                    <Row className=''>
                        <Col md={1}>
                        </Col>
                        <Col md={5} xs={12} className='mt-10 mb-5 business-cont'>
                            <h1 className='mt-5 text-center '>
                                WHY YOU SHOULD HIRE A DIGITAL MARKETING INTERN?
                            </h1>
                            <p>
                                Hire a digital marketing intern for your next digital marketing campaign if you wish to improve your ROI.
                            </p>
                            <p>
                                Digital transformation in today’s digital medium is about campaign planning & timely execution, innovation via the digital medium, enable customer engagement, elevate the brand, and drive growth and transform the organizational process.
                            </p>
                            <center>
                               <a href="#lets-talk"><button className='btn-business-1'>LET'S TALK</button></a> 
                            </center>

                        </Col>
                        <Col md={5} xs={12} className='mt-10 mb-5 business-frm'>
                            <h1 className='mt-5'>HIRE A DIGITAL MARKETING INTERN FOR YOUR BUSINESS</h1>
                            <form className="" onSubmit={handleSubmit}>
                                <input type='text'

                                    name="name"
                                    className='business-field'
                                       value={formValues.name}
                                       onChange={handleChange}
                                    placeholder="Name"
                                />
                                <p className="mb-1 text-light">{formErrors.name}</p>
                                <input type="email"
                                        className='business-field'
                                    name="email"
                                       value={formValues.email}
                                       onChange={handleChange}
                                    placeholder="email"
                                />
                                <p className="mb-1 text-light">{formErrors.email}</p>
                                <input type="tel"
                                       onKeyPress="if(this.value.length==10) return false;"
                                       name="phonenumber" 
                                       value={formValues.phonenumber}
                                       onChange={handleChange}
                                    placeholder="phone number"
                                    className='business-field'
                                />
                                <p className="mb-1 text-light">{formErrors.phonenumber}</p>
                                <textarea
                                    className="mb-3 business-field"
                                    placeholder="message"
                                    name="message"
                                    
                                   value={formValues.message}
                                   onChange={handleChange}
                                >

                                </textarea>
                                <p className="mb-1 text-light">{formErrors.message}</p>



                                {/* <ReCAPTCHA
                                    className="g-recaptcha"
                                    sitekey="6LdqGGQaAAAAABS5Vtjm1NTJ9HdMK8rKjR96V0gE"
                                    onChange={this.onChange}
                                    theme="dark"
                                />
                                { this.state.verified ? <p id="error" className="error">{this.state.reply}</p> : null }
                                { this.state.showSuccess ? <p id="success" className="success">Thank you, we will be in touch asap.</p> : null } */}
                                {/* <p className="mb-4">{formErrors.recaptcha}</p>    */}
                                <button className="bussiness-btn ">send</button>
                                {Object.keys(formErrors).length === 0 && isSubmit ? (<Redirect to='/thank-you-page'  />): (<div></div>) }
                            </form>
                        </Col>
                        <Col md={1}>
                        </Col>
                    </Row>
                </Container>

            </div>
            <div>
                <Container>
                    <Row>
                        <Col className="text-center heading-full-growth">
                            <h1>
                                WHAT CAN YOU EXPECT?
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="full-growth-bg">
                <Container className="mt-2 ">
                    <Row xs="3">
                        <Col md={6} xs={12}>
                            <div className="full-growthcontent">
                                <h6>
                                    Get Certified Interns
                                </h6>
                                <p>
                                    Certified intern – ensuring strict qualification parameters by digiDZN
                                </p>
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <Link to='/seo' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Full Back Up Support
                                    </h6>
                                    <p>
                                        Complete back up by digiDZN’s professional agency team to support your intern & business during the period of internship
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={6} xs={12}>
                            <Link to='/social-media' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Get Dedicated Resource
                                    </h6>
                                    <p>
                                        Dedicated resource trained on Digital Marketing & SEO
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={6} xs={12}>
                            <Link to='/campaigns' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Experienced Interns
                                    </h6>
                                    <p>
                                        Predefined structure for working & review for 3 month internship period
                                    </p>
                                </div>
                            </Link>
                        </Col>


                    </Row>
                    <Row>
                        <Col className="text-center mt-5 mb-5">
                            <Link to='contact-us'> <button className="growth-button">Speak To digidzn</button> </Link>
                        </Col>
                    </Row>
                </Container>
                                <Testimonial />

            </div>
            <div className='mt-5 mb-5' id='lets-talk'>
                <Container>
                    <Row>
                        <Col md={6} xs={12}>
                                <img 
                                className='d-block'
                                width="100%"
                                src={Bsn1}
                                alt="business-img"
                                />
                        </Col>
                        <Col md={6} xs={12}>
                        <h4 className='mt-5 text-center'>LET'S DISCUSS YOUR REQUIREMENT</h4>
                            <Formbus />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    )
}

export default Forbusiness
